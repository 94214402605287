// import SolTab from 'src/components/molecules/tab';
import { TabType } from '../../../../types';
import SolTab from '../../../molecules/tab';
import SolWinLotteries from '../../launchpad-detail/lottery-list';
import './index.scss';
interface SolLotteryDetailTabsProps {
	tabs: TabType[];
	activeTab: any;
	onTabChange: any;
}

const SolLotteryDetailTabs = ({
	tabs = [],
	activeTab,
	onTabChange
}: SolLotteryDetailTabsProps) => {
	return (
		<div className="sol-lottery-detail-tabs">
			<div className="d-flex align-items-center justify-content-between">
				<h3>Leadership</h3>
				<SolWinLotteries />
			</div>

			<div className="tab-list">
				{tabs.map((tab, index) => (
					<SolTab
						key={index}
						text={tab.text}
						active={activeTab === tab.key}
						disabled={tab.disabled}
						onClick={() => onTabChange(tab.key)}
					/>
				))}
			</div>
		</div>
	);
};
export default SolLotteryDetailTabs;
