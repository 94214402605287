import { createAction } from '@reduxjs/toolkit';
import { LotteryListItem } from '../../../types/lottery.type';
import {
	GET_LOTTERIES_ACTION_SUCCESS,
	GET_LOTTERIES_ACTION_REQUEST,
	GET_LOTTERIES_ACTION_FAIL,
} from './types';

export const getLotteries = createAction(
	GET_LOTTERIES_ACTION_REQUEST
);
export const getLotteriesSuccess = createAction<LotteryListItem[]>(
	GET_LOTTERIES_ACTION_SUCCESS);
export const getLotteriesFail = createAction(
	GET_LOTTERIES_ACTION_FAIL
);
