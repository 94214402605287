import * as anchor from '@coral-xyz/anchor';
import { PublicKey } from '@solana/web3.js';

const STAKING_SEEDS = 'BLUE_STAKING';
const REWARD_SEED = 'BLUE_REWARD';
const USER_STAKING_SEED = 'USER_STAKING';

class StakingFindPDA {
	getUserStakingPda = (program: any, pad: PublicKey, user: PublicKey) => {
		const [idoPDAs] = PublicKey.findProgramAddressSync(
			[
				anchor.utils.bytes.utf8.encode(USER_STAKING_SEED),
				pad.toBuffer(),
				user.toBuffer()
			],
			program.programId
		);
		return idoPDAs;
	};

	getPdaStaking = (program: any) => {
		const [idoPDAs] = PublicKey.findProgramAddressSync(
			[anchor.utils.bytes.utf8.encode(STAKING_SEEDS)],
			program.programId
		);
		return idoPDAs;
	};

	getPdaReward = (program: any) => {
		const [idoPDAs] = PublicKey.findProgramAddressSync(
			[anchor.utils.bytes.utf8.encode(REWARD_SEED)],
			program.programId
		);
		return idoPDAs;
	};
}

export const stakingFindPda = new StakingFindPDA();
