import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
	createAssociatedTokenAccountInstruction,
	unpackAccount
} from '@solana/spl-token';
import {
	Commitment,
	ComputeBudgetProgram,
	Connection,
	PublicKey,
	Signer,
	Transaction,
	TransactionInstruction,
	TransactionMessage,
	VersionedTransaction,
	sendAndConfirmTransaction
} from '@solana/web3.js';

export const executedTransaction = async (
	transaction: Transaction,
	connection: Connection
) => {};

export async function sendVersionedTx(
	connection: Connection,
	instructions: TransactionInstruction[],
	payer: PublicKey,
	signers: Signer[]
) {
	let latestBlockHash = await connection.getLatestBlockhash('confirmed');
	const messageLegacy = new TransactionMessage({
		payerKey: payer,
		recentBlockhash: latestBlockHash.blockhash,
		instructions: instructions
	}).compileToLegacyMessage();
	const transaction = new VersionedTransaction(messageLegacy);
	transaction.sign(signers);

	const signature = await connection.sendTransaction(transaction);
	return signature;
}

export async function executeBulkIntrusion(
	solanaConnection: Connection,
	instructions: TransactionInstruction[],
	singer: Signer,

	units?: number
) {
	try {
		const transaction = new Transaction();

		for (let i = 0; i < instructions.length; i++) {
			const ix = instructions[i];
			transaction.add(ix);
		}

		const latestBlockhash = await solanaConnection.getLatestBlockhash();
		transaction.feePayer = singer.publicKey;
		transaction.recentBlockhash = latestBlockhash.blockhash;
		transaction.sign(singer);
		const txSize = transaction.serialize().length;

		//max transaction size is 1232 bytes
		if (txSize > 1232) {
			console.log('Cannot add more instructions to the transaction');
			return;
		}

		//default compute unit is 20000
		const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
			units: units || 200000
		}); //max 1400000
		const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
			microLamports: 1
		});
		transaction.add(modifyComputeUnits, addPriorityFee);
		const tx = await sendAndConfirmTransaction(solanaConnection, transaction, [
			singer
		]);
		return tx;
	} catch (error) {
		console.log('error', error);
		return null;
	}
}

export async function getInstructionCreateAssociatedTokenAccount(
	payer: PublicKey,
	mint: PublicKey,
	owner: PublicKey,
	associatedToken: PublicKey
) {
	return createAssociatedTokenAccountInstruction(
		payer,
		associatedToken,
		owner,
		mint,
		TOKEN_PROGRAM_ID,
		ASSOCIATED_TOKEN_PROGRAM_ID
	);
}

export async function getTokenAccountData(
	connection: Connection,
	associatedToken: PublicKey,
	commitment: Commitment
) {
	try {
		const data = await connection.getAccountInfo(associatedToken, commitment);
		return unpackAccount(associatedToken, data, TOKEN_PROGRAM_ID);
	} catch (error) {
		return null;
	}
}
