import { useDispatch, useSelector } from 'react-redux';
import SolConnectWalletDialog from '../../../../components/organisms/common/connect-wallet-dialog';
import { CONNECT_WALLET_EXTEMSIONS } from '../../../../constants';
import {
	toggleConnectWallet,
	updateWalletInfo
} from '../../../../redux/application/actions';
import { AppState } from '../../../../redux/rootReducer';

const SolConnectWalletContainer = () => {
	const dispatch = useDispatch();

	const showConnectWallet = useSelector((state: AppState) => state.application.showConnectWallet);

	const handleSelectConnect = () => {
		dispatch(
			updateWalletInfo({
				connected: true,
				address: '0xE0493DD5F947A93B8C0d750d317c46F393a0FBA2',
				walletUrl: 'https://google.com'
			})
		);
		dispatch(toggleConnectWallet(false));
	};

	const handleCloseConnectWallet = () => {
		dispatch(toggleConnectWallet(false));
	};

	return (
		<>
			{/* Connect wallet modal */}
			<SolConnectWalletDialog
				show={showConnectWallet}
				extensions={CONNECT_WALLET_EXTEMSIONS}
				onSelect={handleSelectConnect}
				onClose={handleCloseConnectWallet}
			/>
		</>
	);
};
export default SolConnectWalletContainer;
