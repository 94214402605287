import './index.scss';

type SolDashboardTemplateProps = {
	children: JSX.Element;
};

const SolDashboardTemplate = ({ children }: SolDashboardTemplateProps) => {
	return <div className="sol-dashboard-template">{children}</div>;
};
export default SolDashboardTemplate;
