import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Button, Container } from 'react-bootstrap';
import SolDashboardMainContainer from './components/main.container';

const SolDashboard= () => {
	const { select, wallets, connected, publicKey, disconnect } = useWallet();
	return (
		<>
			<SolDashboardMainContainer />
			<Container className="d-none">
				{/* Join pool modal */}
				{/* default connect button */}
				<WalletMultiButton />
				{/* custom connect button */}
				{!(publicKey && connected) ? (
					wallets.filter(wallet => wallet.readyState === 'Installed').length >
						0 ? (
						wallets
							.filter(wallet => wallet.readyState === 'Installed')
							.map(wallet => (
								<Button
									key={wallet.adapter.name}
									onClick={() => select(wallet.adapter.name)}
									style={{ width: '64px', fontSize: '14px' }}
									size="lg"
								// leftIcon={
								//   <Image
								//     src={wallet.adapter.icon}
								//     alt={wallet.adapter.name}
								//     h={6}
								//     w={6}
								//   />
								// }
								>
									{wallet.adapter.name}
								</Button>
							))
					) : (
						<h3>No wallet found. Please download a supported Solana wallet</h3>
					)
				) : (
					<div>
						<h2>{publicKey.toBase58()}</h2>
						<Button onClick={disconnect}>Disconnect wallet</Button>
					</div>
				)}
			</Container>
		</>
	);
};
export default SolDashboard;
