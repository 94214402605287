import { Buffer } from 'buffer';
import { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from '../components/organisms/common/scroll-to-top';
import { APP_ROUTES } from '../constants';
import SolAppPage from '../pages/base/app';
// import SolBridgePage from '../pages/bridge';
import SolDashboard from '../pages/dashboard';
import Error404Page from '../pages/error-page/error-404';
import SolLaunchpadPage from '../pages/launchpad';
import SolLaunchpadDetailPage from '../pages/launchpad-detail';
// import SolStakingPage from '../pages/staking';
import SolSupportsPage from '../pages/supports';
import SolTokenMintingPage from '../pages/token-minting';
import SolLotteryPage from '../pages/lottery';
import SolLotteryDetailPage from '../pages/lottery-detail';
import OverlayLoading from '../components/molecules/overlay-loading';

window.Buffer = Buffer;

function AppRoutes() {
	const location = useLocation();
	return (
		<ScrollToTop>
			<Suspense fallback={<></>}>
				<Routes>
					<Route path="/*" element={<SolAppPage />}>
						<Route
							path={APP_ROUTES.DASHBOARD.path}
							element={<SolDashboard />}
							index
						/>
						<Route
							path={APP_ROUTES.LAUNCHPAD.path}
							element={
								location.pathname === APP_ROUTES.LAUNCHPAD.url ||
								location.pathname === APP_ROUTES.TOKEN_MINTING.url ? (
									<OverlayLoading loading />
								) : (
									<SolLaunchpadPage />
								)
							}
						/>

						<Route
							path={APP_ROUTES.LAUNCHPAD_DETAIL.path}
							element={<SolLaunchpadDetailPage />}
						/>

						<Route
							path={APP_ROUTES.LOTTERY.path}
							element={<SolLotteryPage />}
						/>

						<Route
							path={APP_ROUTES.LOTTERY_DETAIL.path}
							element={<SolLotteryDetailPage />}
						/>
						{/* <Route
							path={APP_ROUTES.STAKING.path}
							element={<SolStakingPage />} /> */}
						<Route
							path={APP_ROUTES.TOKEN_MINTING.path}
							element={
								location.pathname === APP_ROUTES.LAUNCHPAD.url ||
								location.pathname === APP_ROUTES.TOKEN_MINTING.url ? (
									<OverlayLoading loading />
								) : (
									<SolTokenMintingPage />
								)
							}
						/>
						<Route
							path={APP_ROUTES.SUPPORTS.path}
							element={<SolSupportsPage />}
						/>
						<Route path="*" element={<Error404Page />} />
					</Route>
				</Routes>
			</Suspense>
		</ScrollToTop>
	);
}

export default AppRoutes;
