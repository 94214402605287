import { Col, Row } from 'react-bootstrap';
import './index.scss';

type SolLottertDetailTemplateProps = {
	summary: JSX.Element;
	tabs: JSX.Element;
	details: JSX.Element;
};

const SolLotteryDetailTemplate: React.FC<SolLottertDetailTemplateProps> = ({
	summary,
	tabs,
	details
}: SolLottertDetailTemplateProps) => {
	return (
		<div className="sol-lottery-detail-template">
			<Row>
				<Col xxl="3" lg="4" className='mb-4 mb-lg-0'>
					<div className="left-panel">{summary}</div>
				</Col>
				<Col xxl="9" lg="8">
					<div className="right-panel">
						{tabs}
						{details}
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default SolLotteryDetailTemplate;
