import { combineReducers } from 'redux';
import launchpadDetailReducer from '../pages/launchpad-detail/redux/reducer';
import { LauchpadDetailState } from '../pages/launchpad-detail/redux/types';
import stakingReducer from '../pages/staking/redux/reducer';
import { StakingState } from '../pages/staking/redux/types';
import applicationReducer from './application/reducer';
import { ApplicationState } from './application/types';
import lotteryDetailReducer from '../pages/lottery-detail/redux/reducer';
import { LotteryDetailState } from '../pages/lottery-detail/redux/types';
import { WalletState } from './wallet/types';
import walletReducer from './wallet/reducer';
import { LotteryState } from '../pages/lottery/redux/types';
import lotteryListReducer from '../pages/lottery/redux/reducer';

export type AppState = {
	launchpadDetail: LauchpadDetailState;
	lotteryDetail: LotteryDetailState;
	staking: StakingState;
	application: ApplicationState;
	wallet: WalletState;
	lottery: LotteryState
};

const rootReducers = combineReducers({
	launchpadDetail: launchpadDetailReducer,
	lotteryDetail: lotteryDetailReducer,
	staking: stakingReducer,
	application: applicationReducer,
	wallet: walletReducer,
	lottery: lotteryListReducer
});

export default rootReducers;
