import React from 'react';
import SolButton from '../../../atoms/button';

const SolTokenMintingHeader: React.FC = () => {
	return (
		<div className="sol-token-template-header">
			<div className="d-flex flex-column gap-2">
				<h2>Token Minting</h2>
				<span>Create and issue SPL Token</span>
			</div>

			<div className="d-flex align-items-center gap-3">
				<SolButton
					size="sm"
					outline={false}
					className="w-100"
					onClick={() => {}}
				>
					My Token
				</SolButton>
				<SolButton
					size="sm"
					outline={false}
					className="w-100"
					onClick={() => {}}
				>
					OpenBook Manager
				</SolButton>
			</div>
		</div>
	);
};

export default SolTokenMintingHeader;
