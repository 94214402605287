import { PublicKey } from '@solana/web3.js';
import { lotteryWeb3 } from '../../services/blockchain/lottery.web3';
import { LotteryListItem } from '../../types/lottery.type';
import axiosInstance from '../../utils/axios';
import { DEFAULT_MSG_AUTH, LUCKY_WHEEL_STATUS } from '../../constants';
import { get, omit } from 'lodash';
import metadataWeb3 from '../../services/blockchain/metadata.web3';

export const getLotteries = async (): Promise<any> => {
	try {
		const res = await axiosInstance.get('/api/lottery');
		const data = res?.data?.filter(
			(item: LotteryListItem) => item?.state !== LUCKY_WHEEL_STATUS.COMPLETED
		);
		const reqArr = data?.map((item: any) => {
			return lotteryWeb3.getLotteryInfo(
				new PublicKey(String(item?.programId)),
				item?.gameId
			);
		});
		const response = await Promise.all(reqArr);
		console.log('responseresponseresponseresponse', response);
		let result = [];
		if (response) {
			result = data?.map((item: any, index: number) => {
				const openTime = response[index]?.openTimestamp?.toString() * 1000;
				const endTime = response[index]?.endTimestamp?.toString() * 1000;
				console.log('openTime', openTime);
				console.log('endTime', endTime);
				if (openTime && endTime) {
					const tempState = checkLotteryState(
						openTime,
						endTime,
						response[index]
					);
					const temp = omit(response[index], 'id');
					return {
						...item,
						...temp,
						priceTicket: get(response, `[${index}].priceTicket`, 0) / 100,
						state: tempState
					};
				}
				const temp = omit(response[index], 'id');
				return {
					...item,
					...temp,
					priceTicket: get(response, `[${index}].priceTicket`, 0) / 100
				};
			});
		}
		console.log('resullllllll', result);
		return result;
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const checkLotteryState = (
	openTime: number,
	endTime: number,
	lotteryDetail: any
) => {
	if (openTime && endTime) {
		const currentTime = new Date().getTime();
		let status = LUCKY_WHEEL_STATUS.UPCOMING;
		if (openTime > currentTime) status = LUCKY_WHEEL_STATUS.UPCOMING;

		if (openTime < currentTime && endTime > currentTime)
			status = LUCKY_WHEEL_STATUS.OPENING;

		if (
			endTime < currentTime ||
			lotteryDetail?.close ||
			lotteryDetail?.totalTicket >= lotteryDetail?.maxTicket
		)
			status = LUCKY_WHEEL_STATUS.COMPLETED;

		if (
			endTime < currentTime &&
			lotteryDetail?.totalTicket < lotteryDetail?.maxTicket
		) {
			status = LUCKY_WHEEL_STATUS.REFUND;
		}
		if (lotteryDetail?.close && lotteryDetail?.isRewardActive) {
			status = LUCKY_WHEEL_STATUS.REWARD_ACTIVE;
		}
		return status;
	}
};

export const handleAuthWallet = async (
	dataSigned: any,
	address: any
): Promise<any> => {
	try {
		const res = await axiosInstance.post('/api/auth/address', {
			dataSigned: dataSigned,
			message: DEFAULT_MSG_AUTH,
			address
		});
		console.log('handleAuthWallet', res);
		const temp = get(res, 'data.access_token', '');
		if (temp) return temp;
		return false;
	} catch (error) {
		console.log(error);
		return false;
	}
};
export const getLotteryDetailById = async (id: string): Promise<any | null> => {
	try {
		//@ts-ignore

		const res = await axiosInstance.get(`/api/lottery/${id}`);
		if (res?.data === undefined) return null;
		const gameId = get(res, 'data.gameId', 0);
		const programId = res?.data?.programId;
		if (programId === null) return res;
		const info = await lotteryWeb3.getLotteryInfo(programId, parseInt(gameId));
		const rewardCurrencyInfo = await metadataWeb3.getNftMetadata(
			info?.rewardCurrency?.toString()
		);

		const openTime = info?.openTimestamp?.toString() * 1000;
		const endTime = info?.endTimestamp?.toString() * 1000;
		const lotteryState = checkLotteryState(openTime, endTime, info);
		return {
			...res?.data,
			...info,
			state: lotteryState,
			rewardCurrencyDecimals: get(rewardCurrencyInfo, 'decimals', 9)
		};
	} catch (error) {
		console.log(error);
		return null;
	}
};

// export const handleGetProjects = async (
// 	contractAddresses: PublicKey[]
// ): Promise<any> => {
// 	if (contractAddresses.length === 0) return [];

// 	let info = {};
// 	for (const contract of contractAddresses) {
// 		// TO-DO
// 		const idoInfo = await lotteryWeb3.getLotteryInfo(contract, 1);
// 		const contractId = String(contract);

// 		info = {
// 			[contractId]: idoInfo
// 		};
// 	}
// 	return info;
// };
