import { all } from 'redux-saga/effects';
import lunchpadDetailSaga from '../pages/launchpad-detail/redux/saga';
import StakingSaga from '../pages/staking/redux/saga';
import lotteryDetailSaga from '../pages/lottery-detail/redux/saga';
import lotteryListSaga from '../pages/lottery/redux/saga';

function* rootSaga() {
	yield all([
		lunchpadDetailSaga(),
		lotteryDetailSaga(),
		StakingSaga(),
		lotteryListSaga(),
		// Add other sagas here
	]);
}

export default rootSaga;
