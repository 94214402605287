import { Spinner } from 'react-bootstrap';
import './index.scss';
import SolLoader from '../overlay-loader';

interface OverlayLoadingProps {
	loading: boolean;
	type?: 'transaction' | 'normal';
}

const OverlayLoading = ({ loading, type = 'normal' }: OverlayLoadingProps) => {
	return (
		<div className={`sol-overlay ${loading ? 'show' : ''}`}>
			<div className="sol-overlay-content">
				{type === 'transaction' && (
					<>
						<h2>Processing your transaction</h2>
						<p>
							This may take a while. So please be patient <br /> Do not close
							the window or refresh the page during this process
						</p>

						<Spinner animation="border" variant="light" />
					</>
				)}

                {
                    type === 'normal' && <SolLoader size='lg'/>
                }
			</div>
		</div>
	);
};

export default OverlayLoading;
