import { AnchorProvider, Idl, Program, Provider } from '@project-serum/anchor';
import { Connection, PublicKey } from '@solana/web3.js';
import { config, opts } from '../../_config';
import { lotteryFindPda } from '../helpers';
import lotteryIdl from '../idl/galaki_winner_spot_sc.json';

export class LotteryWeb3 {
	private provider: Provider;

	constructor(node_rpc: string) {
		const connection = new Connection(node_rpc, 'processed');
		this.provider = new AnchorProvider(connection, window.solana, opts);
	}

	getLotteryInfo = async (programID: PublicKey, id: number): Promise<any> => {
		try {
			const program = new Program(lotteryIdl as Idl, programID, this.provider);
			const lotteryPda = lotteryFindPda.getLotteryPda(
				new PublicKey(programID),
				id
			);

			const lotteryData = await program.account.galakiGame.fetch(lotteryPda);
			console.log('lotteryDatalotteryData', lotteryData);
			return lotteryData;
		} catch (error) {
			console.log('getLotteryInfo error: ', error);
			return null;
		}
	};
	getPlayerInfo = async (
		programID: PublicKey,
		walletAddress: PublicKey,
		id: number
	): Promise<any> => {
		try {
			console.log('la sao ta 1', id);
			const program = new Program(lotteryIdl as Idl, programID, this.provider);
			const lotteryPda = lotteryFindPda.getLotteryPda(programID, id);
			console.log('la sao ta 2', lotteryPda.toString());

			const playerPda = lotteryFindPda.getPlayerPda(
				programID,
				lotteryPda,
				walletAddress
			);
			console.log('la sao ta 3', playerPda.toString());

			const playerData = await program.account.player.fetch(playerPda);
			return playerData;
		} catch (error) {
			console.log('getPlayerInfo error: ', error);
			return null;
		}
	};
}
export const lotteryWeb3 = new LotteryWeb3(config.SOLANA_RPC);
