import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import './index.scss';

interface ISolLotteryMachineProps {
	circleRound: number;
	targetNumber: number;
	initialIntervalSpeed: number;
	lastIntervalSpeed: number;
}

const SolLotteryMachine: React.FC<ISolLotteryMachineProps> = ({
	circleRound,
	initialIntervalSpeed,
	lastIntervalSpeed,
	targetNumber
}: ISolLotteryMachineProps) => {
	const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	const [count, setCount] = useState(0);
	const [intervalSpeed, setIntervalSpeed] = useState(initialIntervalSpeed);

	useEffect(() => {
		let circle = 0;
		let interval = setInterval(() => {
			setCount(prevCount => {
				const newCount = (prevCount + 1) % 10;
				if (newCount === 0) {
					circle = circle + 1;
				}

				if (circle === circleRound) {
					setIntervalSpeed(lastIntervalSpeed);
				}

				return newCount;
			});
		}, intervalSpeed);

		if (intervalSpeed === 0) {
			clearInterval(interval);
		}

		return () => clearInterval(interval);
	}, [intervalSpeed]);

	useEffect(() => {
		if (intervalSpeed === lastIntervalSpeed && count === targetNumber) {
			setIntervalSpeed(0);
		}
	}, [count, intervalSpeed, targetNumber, lastIntervalSpeed]);

	const numberComponents = useCallback((number: number) => {
		return (
			<AnimatePresence key={number}>
				<motion.div
					initial={{ y: -30, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: 30, opacity: 0 }}
					transition={{ duration: 0.2 }}
					className="sol-lottery-machine-items"
				>
					{number}
				</motion.div>
			</AnimatePresence>
		);
	}, []);

	return (
		<div className="sol-lottery-machine">
			{numbers.map(
				(number) => count === number && numberComponents(number)
			)}
		</div>
	);
};

export default SolLotteryMachine;
