import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './redux/store';
import AppRoutes from './routes';
import './styles/base.scss';

const App = () => {
	return (
		<Provider store={store}>
			<>
				<Router>
					<AppRoutes />
				</Router>
				<ToastContainer />
			</>
		</Provider>
	);
};

export default App;
