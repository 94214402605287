import { put, takeLatest, delay } from 'redux-saga/effects';
import { getLotteries } from '../../../redux/services/lottery';
import { LotteryListItem } from '../../../types/lottery.type';
import { getLotteries as getLotteriesAction, getLotteriesFail, getLotteriesSuccess } from './actions';

function* handleGetLotteryDetail(action: ReturnType<typeof getLotteriesAction>) {
	try {
		const data: LotteryListItem[] = yield getLotteries();
		// debugger
		yield put(getLotteriesSuccess(data));
	} catch (error) {
		yield put(getLotteriesFail());
		console.error('Error fetching data:', error);
	}
}

function* lotteryListSaga() {
	yield takeLatest(getLotteriesAction.type, handleGetLotteryDetail);
}

export default lotteryListSaga;
