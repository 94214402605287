import { Outlet } from 'react-router-dom';
import './index.scss';

type SolAppTemplateProps = {
	sidebar: JSX.Element;
	header: JSX.Element;
};

const SolAppTemplate = ({ sidebar, header }: SolAppTemplateProps) => {
	return (
		<div className="sol-app-template">
			{sidebar}
			<main className="sol-main">
				{header}
				<Outlet />
				<img
					className="decor"
					src="/images/banner-decor.png"
					alt=""
				/>
			</main>
		</div>
	);
};
export default SolAppTemplate;
