import { AnimatePresence, motion } from 'framer-motion';
import './index.scss';
type SolLotteryTemplateProps = {
	section1: JSX.Element;
	section2: JSX.Element;
	section3: JSX.Element;
};

const SolLotteryTemplate = ({
	section1,
	section2,
	section3
}: SolLotteryTemplateProps) => {
	return (
		<AnimatePresence>
			<div className="sol-lottery-template">
				{section1 ? (
					<div className="sol-lottery-section">{section1}</div>
				) : (
					<></>
				)}
				{section2 ? (
					<div className="sol-lottery-section">{section2}</div>
				) : (
					<></>
				)}
				{section3 ? (
					<div className="sol-lottery-section">{section3}</div>
				) : (
					<></>
				)}
				<motion.img
					initial={{ opacity: 0, x: 150 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0, x: 200}}
					transition={{ duration: 0.5 }}
					className="sol-lottery-template-decor"
					src="/images/lottery/MAN.png"
					alt=""
				/>
			</div>
		</AnimatePresence>
	);
};
export default SolLotteryTemplate;
