import { useSelector } from 'react-redux';
import { AppState } from '../redux/rootReducer';
import { get } from 'lodash';

export const useLotteryDetail = () => {
	return useSelector((state: AppState) =>
		get(state, 'lotteryDetail.lottery', null)
	);
};

export const useLotteryProgram = () => {
	return useSelector((state: AppState) =>
		get(state, 'lotteryDetail.lottery.programId', null)
	);
};
