// src/axiosInstance.ts

import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { BASE_URL } from '../_config';



// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: BASE_URL 
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // Do something before the request is sent
        // For example, add an authorization token to headers
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error: AxiosError) => {
        // Handle request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // Do something with response data
        return response;
    },
    (error: AxiosError) => {
        // Handle response error
        if (error.response?.status === 401) {
            // Handle unauthorized error, e.g., redirect to login
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;