import { motion } from 'framer-motion';
import './index.scss';

const SolPageTitle = ({ children }: any) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: -130 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 200 }}
			transition={{ duration: 1 }}
			className="sol-page-title"
		>
			{children}
		</motion.div>
	);
};
export default SolPageTitle;
