import { WalletInfo } from '../../../types/ido.type';
import { LotteryListItem } from '../../../types/lottery.type';

export interface LotteryDetailState {
	lottery?: LotteryListItem;
	isLoading: boolean;
	isLoadingTransaction: boolean;
	walletInfor?: WalletInfo;
	isJoinPoolSuccess: boolean;
	isClaimTokenSuccess: boolean;
	transaction: string;
}

export const GET_LOTTERY_DETAIL_ACTION_REQUEST =
	'GET_LOTTERY_DETAIL_ACTION_REQUEST';
export const GET_LOTTERY_DETAIL_ACTION_SUCCESS =
	'GET_LOTTERY_DETAIL_ACTION_SUCCESS';
export const GET_LOTTERY_DETAIL_ACTION_FAIL = 'GET_LOTTERY_DETAIL_ACTION_FAIL';

export const GET_WALLET_INFOR_ACTION_REQUEST =
	'GET_WALLET_INFOR_ACTION_REQUEST';
export const GET_WALLET_INFOR_ACTION_SUCCESS =
	'GET_WALLET_INFOR_ACTION_SUCCESS';
export const GET_WALLET_INFOR_ACTION_FAIL = 'GET_WALLET_INFOR_ACTION_FAIL';

export const JOIN_POOL_ACTION_REQUEST = 'JOIN_POOL_ACTION_REQUEST';
export const JOIN_POOL_ACTION_SUCCESS = 'JOIN_POOL_ACTION_SUCCESS';
export const JOIN_POOL_ACTION_FAIL = 'JOIN_POOL_ACTION_FAIL';

export const CLAIM_TOKEN_ACTION_REQUEST = 'CLAIM_TOKEN_ACTION_REQUEST';
export const CLAIM_TOKEN_ACTION_SUCCESS = 'CLAIM_TOKEN_ACTION_SUCCESS';
export const CLAIM_TOKEN_ACTION_FAIL = 'CLAIM_ACTION_FAIL';
