import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SolSidebar from '../../../../components/organisms/base/app/sidebar';
import {
	APP_MENUS,
	DEFAULT_MSG_AUTH,
	SITE_CONFIGS
} from '../../../../constants';
import useIsMobile from '../../../../hooks/useIsMobile';
import {
	toggleDarkMode,
	toggleSidebar
} from '../../../../redux/application/actions';
import { AppState } from '../../../../redux/rootReducer';
import { getActiveRoute } from '../../../../services/utils/route.utils';
import { useWallet } from '@solana/wallet-adapter-react';
import { handleAuthWallet } from '../../../../redux/services/lottery';
import { toast } from 'react-toastify';
import { parseJwt } from '../../../../services/helpers';
import { get } from 'lodash';

const SolAppSidebarContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const sidebarExpaned = useSelector(
		(state: AppState) => state.application.sidebarExpaned
	);
	const darkMode = useSelector((state: AppState) => state.application.darkMode);

	const { publicKey, signMessage } = useWallet();

	useEffect(() => {
		if (publicKey && signMessage) {
			handleSignAuthWallet();
		}
	}, [signMessage]);

	const handleSignAuthWallet = async () => {
		try {
			if (signMessage) {
				const accessToken = localStorage.getItem('token');
				if (accessToken) {
					const decodedToken = parseJwt(accessToken);
					console.log('decodedToken', decodedToken);
					const expiredTime = get(decodedToken, 'exp', 0);
					if (expiredTime && expiredTime * 1000 > new Date().getTime()) {
					} else {
						handleSignedMsg();
					}
				} else {
					handleSignedMsg();
				}
			}
		} catch (error) {
			toast.error('Sign wallet failed');
		}
	};

	const handleSignedMsg = async () => {
		try {
			if (signMessage) {
				const encodedMessage = new TextEncoder().encode(DEFAULT_MSG_AUTH);
				const signedMsg = await signMessage(encodedMessage);
				console.log('signedMsg', signedMsg);
				if (signedMsg) {
					const res = await handleAuthWallet(Array.from(signedMsg), publicKey);
					if (!res) {
						toast.error('Sign wallet failed');
					} else {
						localStorage.setItem('token', res);
					}
				}
			}
		} catch (error) {}
	};

	const activeMenu = useMemo(() => {
		const activeRoute = getActiveRoute(location);
		return activeRoute?.url;
	}, [location]);

	useEffect(() => {
		dispatch(toggleSidebar(!isMobile));
	}, [dispatch, isMobile]);

	const handleMenuClick = (menu: any) => {
		collapseSidebar();
		navigate(menu.url);
	};

	const handleToggleMode = () => {
		dispatch(toggleDarkMode(!darkMode));
	};

	const collapseSidebar = () => {
		if (isMobile) {
			dispatch(toggleSidebar(false));
		}
	};

	return (
		<SolSidebar
			expanded={sidebarExpaned}
			siteUrl={SITE_CONFIGS.siteUrl}
			siteTitle={SITE_CONFIGS.siteTitle}
			logoImage={SITE_CONFIGS.logoImage}
			menus={APP_MENUS}
			activeMenu={activeMenu}
			isDarkMode={darkMode}
			onMenuClick={handleMenuClick}
			onToggleMode={handleToggleMode}
			onLogoClick={collapseSidebar}
			onOverlayClick={collapseSidebar}
		/>
	);
};
export default SolAppSidebarContainer;
