import moment from 'moment';
import { formatTimeStampAllocation } from '../../../../services/helpers';
import SolButton from '../../../atoms/button';
import SolInfo from '../../../molecules/info-block';
import './index.scss';

type SolAllocationCardProps = {
	index: any;
	value: string | number;
	percent: number | string;
	claimed: string | number;
	time: number | string;
	status: string | number;
	layout: number;
	onClaim: () => void;
};

const SolAllocationCard = ({
	index,
	value,
	percent,
	claimed,
	time,
	status,
	layout,
	onClaim
}: SolAllocationCardProps) => {


	return (

		<div className="sol-allocation-card">
			<div className="sol-allocation-card-info">
				<SolInfo label="Allocation" value={`${value} (${percent}%)`} />
				<SolInfo label="Claimed" value={claimed} />
				<div className="sol-allocation-card-action">
					{onClaim ? (
						<SolButton disabled={status !== "OPEN"} onClick={onClaim}>Claim tokens</SolButton>
					) : (
						<></>
					)}
				</div>
			</div>
			{layout === 1 && (
				<div className="sol-allocation-card-time">{Number(time) !== 0 ? moment.unix(Number(time)).utc().format('YYYY-MM-DD HH:mm:ss UTC') : "DEX Listing"}</div>
			)}
			{layout === 2 && (
				<div className="sol-allocation-card-time">
					{Number(time) !== 0 ? (<div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(time) }}></div>) : "DEX Listing"}
				</div>
			)}
			<span className="sol-allocation-card-index">#{index}</span>
		</div>
	);
};
export default SolAllocationCard;
