import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import * as Yup from 'yup';
import SolButton from '../../../atoms/button';
import SolFormGroup from '../../../molecules/form-group';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export interface ColourOption {
	readonly value: string;
	readonly label: string;
}

interface FormValuesType {
	tokenName: string;
	symbol: string;
	description?: string;
	webUrl?: string;
	twitterUrl?: string;
	telegramGroupUrl?: string;
	discordUrl?: string;
}

export const suggestionOptions: readonly ColourOption[] = [
	{ value: 'meme', label: 'Meme' },
	{ value: 'airdrop', label: 'Airdrop' },
	{ value: 'fanToken', label: 'Fan Token' },
	{ value: 'tokenization', label: 'Tokenization' },
	{ value: 'rwa', label: 'RWA' },
	{ value: 'nft', label: 'NFT' }
];

type ItemValue = 'splToken' | 'splToken2022';

const tabItems = [
	{ name: 'SPL Token', value: 'splToken' },
	{ name: 'SPL Token 2022', value: 'splToken2022' }
];

const SolTokenInfor: React.FC = () => {
	const [tagValues, setTagValues] = useState<MultiValue<ColourOption>>([]);
	const [radioValue, setRadioValue] = useState<ItemValue>('splToken');

	const formik = useFormik<FormValuesType>({
		initialValues: {
			tokenName: '',
			symbol: '',
			description: '',
			webUrl: '',
			twitterUrl: '',
			telegramGroupUrl: '',
			discordUrl: ''
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.max(15, 'Must be 15 characters or less')
				.required('Required'),
			email: Yup.string().email('Invalid email address').required('Required'),
			password: Yup.string()
				.min(6, 'Must be 6 characters or more')
				.required('Required')
		}),
		onSubmit: values => {}
	});

	const handleChangeTags = (option: ColourOption) => {
		const newTagValue = cloneDeep(tagValues);

		if (newTagValue.includes(option)) {
			const newValue: MultiValue<ColourOption> = newTagValue.filter(
				(item, index) => {
					return newTagValue.indexOf(option) !== index;
				}
			);
			setTagValues(newValue);
		} else {
			setTagValues([...newTagValue, option]);
		}
	};

	return (
		<div className="sol-token-template-infor">
			<div className="infor-decor">
				<span className="infor-decor-inner"></span>
			</div>
			<h2 className="text-uppercase fs-4 mb-4">Token Information</h2>

			<ButtonGroup className="mb-4">
				{tabItems.map((item, idx) => (
					<ToggleButton
						key={idx}
						id={`item-${idx}`}
						type="radio"
						className={`${item.value === radioValue ? 'active' : ''} `}
						name="item"
						value={item.value}
						checked={radioValue === item.value}
						onChange={e => setRadioValue(e.currentTarget.value as ItemValue)}
					>
						{item.name}
					</ToggleButton>
				))}
			</ButtonGroup>
			<div className="sol-token-template-infor-header mb-4">
				<h3>Token Information</h3>
				<p>
					This information is stored on IPFS by IRYS + Metaplex Metadata
					standard.
				</p>
			</div>

			<form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3">
				<SolFormGroup
					label="Token Name (ex. Dexlab)"
					placeholder="Enter token name"
					value={formik.values.tokenName}
					onChange={formik.handleChange}
					size="sm"
					id="tokenName"
					name="tokenName"
				/>
				{formik.touched.tokenName && formik.errors.tokenName ? (
					<div>{formik.errors.tokenName}</div>
				) : null}

				<SolFormGroup
					label="Symbol (Max 10. ex. DXL)"
					placeholder="Enter token symbol"
					value={formik.values.symbol}
					onChange={formik.handleChange}
					size="sm"
					id="symbol"
					name="symbol"
				/>

				{formik.touched.symbol && formik.errors.symbol ? (
					<div>{formik.errors.symbol}</div>
				) : null}

				<SolFormGroup
					label="(Optional) Description"
					placeholder="Enter project description"
					value={formik.values.description}
					onChange={formik.handleChange}
					size="sm"
					isTextArea
					id="description"
					name="description"
				/>

				{formik.touched.description && formik.errors.description ? (
					<div>{formik.errors.description}</div>
				) : null}

				<SolFormGroup
					label="(Optional) Extensions"
					placeholder="Website URL"
					value={formik.values.webUrl}
					onChange={formik.handleChange}
					size="sm"
					id="webUrl"
					name="webUrl"
				/>

				<SolFormGroup
					label=""
					placeholder="Twitter URL"
					value={formik.values.twitterUrl}
					onChange={formik.handleChange}
					size="sm"
					id="twitterUrl"
					name="twitterUrl"
				/>

				<SolFormGroup
					label=""
					placeholder="Telegram Group URL"
					value={formik.values.telegramGroupUrl}
					onChange={formik.handleChange}
					size="sm"
					id="telegramGroupUrl"
					name="telegramGroupUrl"
				/>

				<SolFormGroup
					label=""
					placeholder="Discord URL"
					value={formik.values.telegramGroupUrl}
					onChange={formik.handleChange}
					size="sm"
					id="telegramGroupUrl"
					name="telegramGroupUrl"
					onBlur={formik.handleBlur}
				/>

				<span>(Optional) Tags - Max 5 tags</span>

				<Select
					defaultValue={[suggestionOptions[2], suggestionOptions[3]]}
					isMulti
					name="colors"
					options={suggestionOptions}
					className="basic-multi-select"
					classNamePrefix="select"
					placeholder=""
					value={tagValues}
					onChange={value => {
						setTagValues(value);
					}}
					menuIsOpen={false}
				/>

				<div className="d-flex align-items-center gap-2 sol-token-template-suggestion">
					<span>Suggestion:</span>

					{suggestionOptions.map(option => (
						<span
							className="options"
							onClick={() => handleChangeTags(option)}
							key={option.value}
						>
							{option.label}
						</span>
					))}
				</div>

				<SolButton onClick={() => {}} size="lg" outline={false}>
					Connect Wallet
				</SolButton>
			</form>
			<span className="infor-decor-bottom"></span>
		</div>
	);
};

export default SolTokenInfor;
