import SolAppTemplate from '../../../../components/templates/base/app';
import { WalletContext } from '../../../../components/wallet-context';
import AppUpdater from '../../../../hooks/updater';
import SolConnectWalletContainer from './connect-wallet.container';
import SolAppHeaderContainer from './header.container';
import SolAppSidebarContainer from './sidebar.container';

const SolAppMainContainer = () => {
	return (
		<WalletContext>
			<>
				<AppUpdater />
				<SolAppTemplate
					sidebar={<SolAppSidebarContainer />}
					header={<SolAppHeaderContainer />}
				/>
				<SolConnectWalletContainer />
			</>
		</WalletContext>
	);
};
export default SolAppMainContainer;
