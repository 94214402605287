import {
	PublicKey,
	ConfirmOptions,
	SystemProgram,
	Connection,
	ComputeBudgetProgram,
	sendAndConfirmRawTransaction,
	Transaction
} from '@solana/web3.js';
import {
	AnchorWallet,
	ConnectionContextState
} from '@solana/wallet-adapter-react';
import lotteryIdl from '../idl/galaki_winner_spot_sc.json';

import {
	AnchorProvider,
	BN,
	Idl,
	Program,
	Provider
} from '@project-serum/anchor';
import { lotteryFindPda } from '../helpers';
import {
	TOKEN_PROGRAM_ID,
	ASSOCIATED_TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
	getOrCreateAssociatedTokenAccount
} from '@solana/spl-token';
import { config, opts } from '../../_config';
import {
	ClaimRewardParams,
	ParticipateLotteryParams,
	RefundParams
} from '../../types/lottery.type';
import {
	getInstructionCreateAssociatedTokenAccount,
	getTokenAccountData
} from './utils';

export class LotteryService {
	private provider: Provider;
	private connection: Connection;

	constructor(node_rpc: string) {
		this.connection = new Connection(node_rpc, 'processed');
		this.provider = new AnchorProvider(this.connection, window.solana, opts);
	}

	async joinLottery(
		anchorWallet: AnchorWallet,
		param: ParticipateLotteryParams
	) {
		console.log(
			'joinLottery',
			param?.currency.toString(),
			param?.programID?.toString(),
			param?.id
		);
		try {
			const program = this.getLotteryProgram(
				this.connection,
				anchorWallet,
				param.programID
			);
			const lotteryPda = lotteryFindPda.getLotteryPda(
				param.programID,
				param?.id
			);
			console.log('lotteryPda', lotteryPda.toString());
			console.log('userPdauserPdauserPdauserPda', param);
			const userPda = lotteryFindPda.getPlayerPda(
				param.programID,
				lotteryPda,
				anchorWallet.publicKey
			);
			const whiteListPda = lotteryFindPda.getWhiteListPda(
				param.programID,
				lotteryPda
			);

			const mint = param.currency;
			console.log('asajshasasasas', mint.toString());
			if (!mint || mint.toString() === PublicKey.default.toString()) {
				const transaction = await program.methods
					.participateNative()
					.accounts({
						galakiGamePda: lotteryPda,
						whiteListPda: whiteListPda,
						userPda: userPda,
						user: anchorWallet.publicKey,
						systemProgram: SystemProgram.programId
					})
					.rpc();
				return {
					status: true,
					message: 'success',
					data: transaction
				};
			}
			const userAta = getAssociatedTokenAddressSync(
				mint,
				anchorWallet.publicKey,
				true
			);
			let transaction = new Transaction();

			//get data of userAta
			const userTokenData = await getTokenAccountData(
				this.connection,
				userAta,
				opts.commitment || 'processed'
			);
			console.log('userTokenData', userTokenData);

			// if userToken not exist
			if (!userTokenData) {
				const createTokenAccountIns =
					await getInstructionCreateAssociatedTokenAccount(
						anchorWallet.publicKey,
						mint,
						anchorWallet.publicKey,
						userAta
					);
				transaction.add(createTokenAccountIns);
			}

			const participateIns = await program.methods
				.participate()
				.accounts({
					galakiGamePda: lotteryPda,
					whiteListPda: whiteListPda,
					userPda: userPda,
					galakiGameAta: getAssociatedTokenAddressSync(mint, lotteryPda, true),
					userAta: getAssociatedTokenAddressSync(
						mint,
						anchorWallet.publicKey,
						true
					),
					tokenMint: mint,
					user: anchorWallet.publicKey,
					tokenProgram: TOKEN_PROGRAM_ID,
					associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
					systemProgram: SystemProgram.programId
				})
				.instruction();

			transaction.add(participateIns);

			//doing
			const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
				units: 2000000
			});

			const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
				microLamports: 1
			});

			const latestBlockhash = (await this.connection.getLatestBlockhash())
				.blockhash;

			transaction.add(modifyComputeUnits).add(addPriorityFee);

			transaction.recentBlockhash = latestBlockhash;
			transaction.feePayer = anchorWallet.publicKey;
			const signature = await anchorWallet.signTransaction(transaction);
			const txHash = await sendAndConfirmRawTransaction(
				this.connection,
				signature.serialize()
			);
			console.log('txHash=====', txHash);
			return {
				status: true,
				message: 'success',
				data: txHash
			};
		} catch (error: any) {
			console.log('join error: ', error);
			return {
				status: false,
				message: error.message
			};
		}
	}

	async claimReward(anchorWallet: AnchorWallet, param: ClaimRewardParams) {
		console.log('param', param);
		console.log('paraamamamamama', param?.id?.toString());
		console.log('paraamamamamama', param?.programID?.toString());
		console.log('paraamamamamama', param?.rewardCurrency?.toString());
		try {
			const { programID, id, rewardCurrency } = param;
			const program = this.getLotteryProgram(
				this.connection,
				anchorWallet,
				param.programID
			);

			const [lotteryPda, _, userPda] = this.getLotteryPda(
				programID,
				id,
				anchorWallet.publicKey
			);

			if (
				!rewardCurrency ||
				rewardCurrency.toString() === PublicKey.default.toString()
			) {
				const transaction = await program.methods
					.claimRewardNative()
					.accounts({
						galakiGamePda: lotteryPda,
						userPda: userPda,
						user: this.provider.publicKey,
						systemProgram: SystemProgram.programId
					})
					.rpc();
				return {
					status: true,
					message: 'success',
					data: transaction
				};
			}
			const transaction = await program.methods
				.claimRewardSpl()
				.accounts({
					galakiGamePda: lotteryPda,
					gameAta: getAssociatedTokenAddressSync(
						rewardCurrency,
						lotteryPda,
						true
					),
					userPda: userPda,
					userAta: getAssociatedTokenAddressSync(
						rewardCurrency,
						anchorWallet.publicKey,
						true
					),
					user: this.provider.publicKey,
					rewardTokenMint: rewardCurrency,
					tokenProgram: TOKEN_PROGRAM_ID,
					associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
					systemProgram: SystemProgram.programId
				})
				.rpc();
			return {
				status: true,
				message: 'success',
				data: transaction
			};

			// const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
			// 	units: 1000000
			// });

			// const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
			// 	microLamports: 1
			// });

			// const latestBlockhash = (await this.connection.getLatestBlockhash())
			// 	.blockhash;
			// const instruction = await this.getInxClaimReward(anchorWallet, param);
			// const transaction = new Transaction()
			// 	.add(modifyComputeUnits)
			// 	.add(addPriorityFee)
			// 	.add(instruction);
			// transaction.recentBlockhash = latestBlockhash;
			// transaction.feePayer = anchorWallet.publicKey;
			// const signature = await anchorWallet.signTransaction(transaction);
			// const txHash = await sendAndConfirmRawTransaction(
			// 	this.connection,
			// 	signature.serialize()
			// );
			// console.log('txHash=====', txHash);

			// console.log('Your transaction signature with buyBoxSpl', transaction);
			// return {
			// 	status: true,
			// 	message: 'success',
			// 	data: txHash
			// };
		} catch (error: any) {
			console.log('claim error: ', error);
			return {
				status: false,
				message: error.message
			};
		}
	}

	getInxClaimReward(anchorWallet: AnchorWallet, param: ClaimRewardParams) {
		const { programID, id, rewardCurrency } = param;
		const program = this.getLotteryProgram(
			this.connection,
			anchorWallet,
			param.programID
		);

		const [lotteryPda, _, userPda] = this.getLotteryPda(
			programID,
			id,
			anchorWallet.publicKey
		);

		if (
			!rewardCurrency ||
			rewardCurrency.toString() === PublicKey.default.toString()
		) {
			return program.methods
				.claimRewardNative()
				.accounts({
					galakiGamePda: lotteryPda,
					userPda: userPda,
					user: this.provider.publicKey,
					systemProgram: SystemProgram.programId
				})
				.instruction();
		}
		return program.methods
			.claimRewardSpl()
			.accounts({
				galakiGamePda: lotteryPda,
				gameAta: getAssociatedTokenAddressSync(
					rewardCurrency,
					lotteryPda,
					true
				),
				userPda: userPda,
				userAta: getAssociatedTokenAddressSync(
					rewardCurrency,
					anchorWallet.publicKey,
					true
				),
				user: this.provider.publicKey,
				rewardTokenMint: rewardCurrency,
				tokenProgram: TOKEN_PROGRAM_ID,
				associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
				systemProgram: SystemProgram.programId
			})
			.instruction();
	}

	async handleRefund(anchorWallet: AnchorWallet, param: RefundParams) {
		try {
			const { programID, gameId, currency } = param;
			console.log('currency=====', currency?.toString());
			const [lotteryPda, _, userPda] = this.getLotteryPda(
				programID,
				gameId,
				anchorWallet.publicKey
			);
			const program = this.getLotteryProgram(
				this.connection,
				anchorWallet,
				param.programID
			);
			console.log('currency', currency.toString());

			if (!currency || currency.toString() === PublicKey.default.toString()) {
				const transaction = program.methods
					.refundSol()
					.accounts({
						galakiGamePda: lotteryPda,
						userPda: userPda,
						user: anchorWallet.publicKey,
						systemProgram: SystemProgram.programId
					})
					.rpc();
				return {
					status: true,
					message: 'success',
					data: transaction
				};
			}

			const transaction = await program.methods
				.refund()
				.accounts({
					galakiGamePda: lotteryPda,
					gameAta: getAssociatedTokenAddressSync(currency, lotteryPda, true),
					userPda: userPda,
					userAta: getAssociatedTokenAddressSync(
						currency,
						anchorWallet.publicKey,
						true
					),
					user: this.provider.publicKey,
					currencyMint: currency,
					tokenProgram: TOKEN_PROGRAM_ID,
					associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
					systemProgram: SystemProgram.programId
				})
				.rpc();
			return {
				status: true,
				message: 'success',
				data: transaction
			};
		} catch (error: any) {
			console.log('handleRefund err', error);
			return {
				status: false,
				message: error?.message
			};
		}
	}

	async getInxRefund(anchorWallet: AnchorWallet, param: RefundParams) {
		const { programID, gameId, currency } = param;
		const [lotteryPda, _, userPda] = this.getLotteryPda(
			programID,
			gameId,
			anchorWallet.publicKey
		);
		const program = this.getLotteryProgram(
			this.connection,
			anchorWallet,
			param.programID
		);
		console.log('currency', currency.toString());

		return await program.methods
			.refund()
			.accounts({
				galakiGamePda: lotteryPda,
				gameAta: getAssociatedTokenAddressSync(currency, lotteryPda, true),
				userPda: userPda,
				userAta: getAssociatedTokenAddressSync(
					currency,
					anchorWallet.publicKey,
					true
				),
				user: this.provider.publicKey,
				currencyMint: currency,
				tokenProgram: TOKEN_PROGRAM_ID,
				associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
				systemProgram: SystemProgram.programId
			})
			.instruction();
	}

	private getLotteryProgram(
		connection: Connection,
		anchorWallet: AnchorWallet,
		programID: PublicKey
	) {
		const provider = this._getProvider(connection, anchorWallet);
		return new Program(lotteryIdl as Idl, programID, provider);
	}

	private getLotteryPda(
		programID: PublicKey,
		gameId: number,
		wallet: PublicKey
	): [PublicKey, PublicKey, PublicKey] {
		const lotteryPda = lotteryFindPda.getLotteryPda(programID, gameId);
		const whilelistPda = lotteryFindPda.getWhiteListPda(programID, lotteryPda);
		const userPda = lotteryFindPda.getPlayerPda(programID, lotteryPda, wallet);
		return [lotteryPda, whilelistPda, userPda];
	}

	private _getProvider = (
		connection: Connection,
		anchorWallet: AnchorWallet
	) => {
		return new AnchorProvider(connection, anchorWallet, opts);
	};
}

export const lotteryService = new LotteryService(config.SOLANA_RPC);
