import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { motion, useInView } from 'framer-motion';

export interface ISolLotteryItem {
	id: number;
	image: string;
	name: string;
	totalReward: string | number;
	maxWin: string | number;
	ticketPrice: string | number;
	gameName: string;
	rewardCurrencyDecimal: number;
	rewardCurrencySymbol: string;
	currencySymbol: string;
	maxWinner: number;
}
interface ISolLotteryCardProps {
	data: ISolLotteryItem;
	color?: string;
	link: string;
}

const SolLotteryCard: React.FC<ISolLotteryCardProps> = ({
	data,
	color = '#23e777',
	link
}: ISolLotteryCardProps) => {
	const ref = useRef(null);
	const isInView = useInView(ref);

	return (
		<motion.div
			ref={ref}
			initial={{ opacity: 0 }}
			animate={
				isInView
					? { opacity: 1, transition: { duration: 1, ease: 'easeInOut' } }
					: { opacity: 0 }
			}
		>
			<Link
				to={`/${link.replace(':id', data.id.toString())}`}
				className="sol-lottery-card"
			>
				<div className="image">
					<img src={data.image} alt={data.name} />
				</div>
				<div className="inner">
					<div className="name" style={{ color: color }}>
						{data.name}
					</div>
					<div className="price">
						{Number(data.totalReward) / 10 ** data.rewardCurrencyDecimal}{' '}
						{data.rewardCurrencySymbol}
					</div>
					<div className="more">
						<div>
							<span>Ticket price: </span>
							<b style={{ color: color }}>
								{data.ticketPrice} {data.currencySymbol}
							</b>
						</div>
						<div>
							<span>Max win: </span>
							<b style={{ color: color }}>
								{(Number(data.totalReward) * data?.maxWinner) /
									10 ** data.rewardCurrencyDecimal}{' '}
								{data.rewardCurrencySymbol}
							</b>
						</div>
						<div>
							<span>Name: </span>
							<b style={{ color: color }}>{data.gameName}</b>
						</div>
					</div>
				</div>
			</Link>
		</motion.div>
	);
};

export default SolLotteryCard;
