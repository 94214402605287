import SolLotteryUpcoming from '../../../components/organisms/lottery/upcoming-lotteries';
import { LotteryListItem } from '../../../types/lottery.type';

interface SolLotteryUpcomingContainerProps {
	projects: LotteryListItem[];
}
const SolLotteryUpcomingContainer = ({
	projects
}: SolLotteryUpcomingContainerProps) => {
	return (
		<SolLotteryUpcoming
			sectionTitle="Upcoming Lucky Wheel"
			projects={projects}
		/>
	);
};
export default SolLotteryUpcomingContainer;
