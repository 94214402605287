import { solaUtils } from '../../services/blockchain';
import { ProjectDetail } from '../../types';
import axiosInstance from '../../utils/axios';

export const getIdoProjects = async (): Promise<any> => {
	try {
		const res = await axiosInstance.get('/api/ido');

		//@ts-ignore
		const contractAddresses = res?.data
			.filter(
				(project: ProjectDetail) =>
					project.contract !== null && project.state !== 'C'
			)
			.map((project: ProjectDetail) => project.contract);

		const info = await handleGetProjects(contractAddresses);

		//@ts-ignore
		const data = res?.data?.map((project: ProjectDetail): ProjectDetail => {
			if (project.contract !== null && project.state !== 'C') {
				return project.contract !== 'TBA'
					? {
							...project,
							...info[project.contract]
					  }
					: project;
			}
			return project;
		});

		return data;
	} catch (error) {
		console.log(error);
		return [];
	}
};
export const getProjectDetailById = async (
	id: number | string
): Promise<any | null> => {
	try {
		//@ts-ignore

		const res = await axiosInstance.get(`/api/ido/${id}`);
		if (res?.data === undefined) return null;
		const contract = res.data?.contract;
		if (contract === null) return res;
		const info = await solaUtils.getIdoDetail(contract);
		console.log('getProjectDetail', info);
		return {
			...res?.data,
			...info
		};
	} catch (error) {
		console.log(error);
		return null;
	}
};

const handleGetProjects = async (contractAddresses: string[]): Promise<any> => {
	if (contractAddresses.length === 0) return [];
	let info = {};
	for (const contract of contractAddresses) {
		console.log('contract', contract);
		if (contract !== 'TBA') {
			const idoInfo = await solaUtils.getIdoDetail(contract);
			//@ts-ignore
			info[contract] = idoInfo;
		}
	}
	return info;
};
