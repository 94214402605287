import { motion, useInView } from 'framer-motion';
import { get } from 'lodash';
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { APP_ROUTES, GAME_TYPES } from '../../../../constants';
import { LotteryListItem } from '../../../../types/lottery.type';
import SolLotteryCard from '../lottery-card';
import './index.scss';

type SolLotteryCompletedProps = {
	sectionTitle?: string;
	projects: LotteryListItem[] | any[];
};

const SolLotteryCompleted = ({
	sectionTitle = '',
	projects
}: SolLotteryCompletedProps) => {
	const ref = useRef(null);
	const isInView = useInView(ref);
	return (
		<>
			{sectionTitle ? (
				<motion.h3
					ref={ref}
					initial={{ opacity: 0, x: -50 }}
					animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
					transition={{ duration: 0.5 }}
				>
					{sectionTitle}
				</motion.h3>
			) : (
				<></>
			)}

			{projects.length ? (
				<Row>
					<Col xxl={9}>
						<Row>
							{projects.map((project, index) => (
								<Col key={index} xxl="4" xl="4" sm="6">
									<SolLotteryCard
										data={{
											id: project?.id,
											image: get(GAME_TYPES, `[${project?.type}].src`, ''),
											name: get(GAME_TYPES, `[${project?.type}].name`, ''),
											totalReward: `${get(project, 'prize', 0)}`,
											maxWin: `${get(project, 'prize', 0)}x`,
											ticketPrice: get(project, 'priceTicket', 0),
											gameName: get(project, 'name', ''),
											rewardCurrencyDecimal: get(
												project,
												'rewardCurrencyDecimal',
												0
											),
											rewardCurrencySymbol: get(
												project,
												'rewardCurrencySymbol',
												''
											),
											currencySymbol: get(project, 'currencySymbol', ''),
											maxWinner: get(project, 'maxWinner', 1)
										}}
										color={get(
											GAME_TYPES,
											`[${project?.type}].color`,
											'#9345EC'
										)}
										link={APP_ROUTES.LOTTERY_DETAIL.path}
									/>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			) : (
				<Row>
					<Col xxl={9}>
						<div className="no-items">No items found.</div>
					</Col>
				</Row>
			)}
		</>
	);
};
export default SolLotteryCompleted;
