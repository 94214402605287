import SolLaunchpadUpcoming from '../../../components/organisms/launchpad/upcoming-launchpads';
import { ProjectDetail } from '../../../types';

interface SolLaunchpadUpcomingContainerProps {
	projects: ProjectDetail[];
}
const SolLaunchpadUpcomingContainer = ({ projects }: SolLaunchpadUpcomingContainerProps) => {
	return (
		<SolLaunchpadUpcoming
			sectionTitle="Upcoming Launchpads"
			projects={projects}
		/>
	);
};
export default SolLaunchpadUpcomingContainer;
