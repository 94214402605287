import { useSelector } from 'react-redux';
import { AppState } from '../redux/rootReducer';

export const useSolBalance = () => {
	return useSelector((state: AppState) => state.wallet.solBal);
};

export const useUsdcBalance = () => {
	return useSelector((state: AppState) => state.wallet.usdcBal);
};

export const useBlockLatest = () => {
	return useSelector((state: AppState) => state.application.blockNumber);
};
