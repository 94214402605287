import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import SolHeader from '../../../../components/organisms/base/app/header';
import SolYourWalletDialog from '../../../../components/organisms/common/your-wallet-dialog';
import {
	toggleSidebar,
	updateBreadcrumbs
} from '../../../../redux/application/actions';
import { AppState } from '../../../../redux/rootReducer';
import { getBreadcrumbs } from '../../../../services/utils/route.utils';
import OverlayLoading from '../../../../components/molecules/overlay-loading';
import { APP_ROUTES } from '../../../../constants';

const SolAppHeaderContainer = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const params = useParams();

	const breadcrumbs = useSelector(
		(state: AppState) => state.application.breadcrumbs
	);

	const sidebarExpaned = useSelector(
		(state: AppState) => state.application.sidebarExpaned
	);

	const walletInfo = useSelector(
		(state: AppState) => state.application.walletInfo
	);

	const [showYourWalletModal, setShowYourWalletModal] = useState(false);

	useEffect(() => {
		const breadcrumbs = getBreadcrumbs(location, params);
		dispatch(updateBreadcrumbs(breadcrumbs));
	}, [dispatch, location, params]);

	const handleShowYourWallet = () => {
		setShowYourWalletModal(true);
	};

	const handleToggleSidebar = () => {
		dispatch(toggleSidebar(!sidebarExpaned));
	};

	return (
		<>
			{(location.pathname === APP_ROUTES.LAUNCHPAD.url ||
				location.pathname === APP_ROUTES.TOKEN_MINTING.url) && (
				<OverlayLoading loading />
			)}
			{/* Header organisms */}
			<SolHeader
				breadcrumbs={breadcrumbs || []}
				onClickWallet={handleShowYourWallet}
				onToggleSidebar={handleToggleSidebar}
			/>

			{/* Your wallet info modal */}
			<SolYourWalletDialog
				show={showYourWalletModal}
				walletAddress={walletInfo?.address}
				walletUrl={walletInfo?.walletUrl}
				onClose={() => setShowYourWalletModal(false)}
			/>
		</>
	);
};
export default SolAppHeaderContainer;
