import { config, opts } from '../../_config';
import { Connection, PublicKey } from '@solana/web3.js';
import { AnchorProvider, Provider } from '@project-serum/anchor';
import { get } from 'lodash';

class MetadataWeb3 {
	private provider: Provider;
	private connection: Connection;

	constructor(node_rpc: string) {
		this.connection = new Connection(node_rpc, 'processed');
		this.provider = new AnchorProvider(this.connection, window.solana, opts);
	}

	async getNftMetadata(assetId: string) {
		try {
			const mint = await this.connection.getParsedAccountInfo(
				new PublicKey(assetId)
			);

			// all the token data is here
			return get(mint, 'value.data.parsed.info', null);
		} catch (e) {
			return null;
		}
	}
}
const metadataWeb3 = new MetadataWeb3(config.SOLANA_RPC);

export default metadataWeb3;
