import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import SolPagination from '../../../molecules/pagination';
import './index.scss';
import { TABS } from '../../../../pages/lottery-detail/components/main.container';
import { zeroPadding } from '../../../../services/helpers';

export interface ISolLeadership {
	username: string;
	avatar: string;
	price: string | number;
	number: string | number;
	game: string;
	key: any;
	currencyName?: string;
}
export interface ISolLeadershipProps {
	data: ISolLeadership[];
	activeTab: string;
}
const SolLeadership = ({ data, activeTab }: ISolLeadershipProps) => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState(8);
	const [count, setCount] = useState(0);

	useEffect(() => {
		if (activeTab) {
			setCurrentPage(1);
			setCount(count + 1);
		}
	}, [activeTab]);

	const dataList = useMemo(() => {
		if (!data.length) return [];

		const newData = cloneDeep(data);

		return newData.filter(
			(item, index) =>
				item &&
				index >= (currentPage - 1) * pageSize &&
				index < currentPage * pageSize
		);
	}, [currentPage, pageSize, data]);

	return (
		<div className="sol-leadership">
			<Table responsive data-bs-theme="dark">
				<thead>
					<tr>
						<th>Player</th>
						<th className="text-center">Price</th>
						<th className="text-center">Lucky Number</th>
						{/* <th className="text-center">Multiplayer</th> */}
						<th>Game</th>
					</tr>
				</thead>
				<tbody>
					{dataList?.map(item => (
						<tr key={item.key}>
							<td>
								<div className="user-cell">
									<div className="avatar">
										<span>
											<img src={item.avatar} alt={item.username} />
										</span>
									</div>
									<div className={`info`}>
										<b
											className={`${
												activeTab === TABS[0]?.key ? 'info-prevent-copy' : ''
											}`}
										>
											{item.username}
										</b>
										{/* <span>{item.level}</span> */}
									</div>
								</div>
							</td>
							<td className="text-center">
								<span>
									{item.price} {item?.currencyName}
								</span>
							</td>
							<td className="text-center">
								<span>{zeroPadding(item?.number)}</span>
							</td>
							{/* <td className="text-center">
								<span>{item.multiplayer}</span>
							</td> */}
							<td>
								<span>{item.game}</span>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			{data.length ? (
				<div className="mt-2">
					<SolPagination
						key={count}
						total={data.length}
						activePage={currentPage}
						onChange={page => setCurrentPage(page)}
						pageSize={pageSize}
					/>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
export default SolLeadership;
