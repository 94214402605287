import { createReducer } from '@reduxjs/toolkit';
import {
	getLotteries,
	getLotteriesFail,
	getLotteriesSuccess
} from './actions';
import { LotteryState } from './types';

const initialState: LotteryState = {
	lotteries: [],
	isLoading: false
};

const lotteryListReducer = createReducer(initialState, builder => {
	builder
		.addCase(getLotteries, (state, action) => {
			state.isLoading = true;
		})
		.addCase(getLotteriesSuccess, (state: LotteryState, action) => {
			state.isLoading = false;
			state.lotteries = action.payload;
		})
		.addCase(getLotteriesFail, (state: LotteryState, action) => {
			state.isLoading = false;
			state.lotteries = [];
		})
});

export default lotteryListReducer;
