import SolLaunchpadCompleted from '../../../components/organisms/launchpad/completed-launchpads';
import { ProjectDetail } from '../../../types';

interface SolLaunchpadCompletedContainerProps {
	projects: ProjectDetail[];
}
const SolLaunchpadCompletedContainer = ({projects}: SolLaunchpadCompletedContainerProps) => {
	return (
		<SolLaunchpadCompleted
			sectionTitle="Completed Launchpads"
			projects={projects}
		/>
	);
};
export default SolLaunchpadCompletedContainer;
