import { Key } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	formatNumberDownRound,
	formatTokenAllocation
} from '../../../../services/helpers';
import { AllocationItem } from '../../../../types/ido.type';
import SolAllocationCard from '../../common/allocation-card';
import './index.scss';

interface SolLaunchpadDetailAllocationProps {
	tokenDecimals: number;
	allocations: Array<AllocationItem>;
	layout: number;
	onClaim: (index: number) => void;
	tokenSymbol: string | number;
}

const SolLaunchpadDetailAllocation = ({
	allocations,
	tokenDecimals,
	layout,
	onClaim,
	tokenSymbol
}: SolLaunchpadDetailAllocationProps) => {
	return (
		<div className="sol-launchpad-detail-allocation">
			{layout === 1 && allocations.length > 0 && (
				<Row>
					{allocations.map(
						(allocation: AllocationItem, index: Key | null | undefined) => (
							<Col lg="6" key={index}>
								<SolAllocationCard
									index={allocation.no}
									value={`${formatNumberDownRound(
										Number(allocation?.allocationAmount) | 0,
										tokenDecimals
									)} ${tokenSymbol}`}
									percent={Number(allocation.percentage) / 100}
									claimed={`${formatNumberDownRound(
										Number(allocation?.claimedAmount) | 0,
										tokenDecimals
									)} ${tokenSymbol}`}
									time={Number(allocation.timestamp)}
									onClaim={() => onClaim(Number(allocation.no))}
									status={allocation.status}
									layout={layout}
								/>
							</Col>
						)
					)}
				</Row>
			)}
			{layout === 2 && allocations.length > 0 && (
				<Row>
					{allocations.map(
						(allocation: AllocationItem, index: Key | null | undefined) => (
							<Col lg="6" key={index}>
								<SolAllocationCard
									index={allocation.no}
									value={`${formatTokenAllocation(
										allocation.allocationAmount,
										tokenDecimals
									)} ${tokenSymbol}`}
									percent={Number(allocation.percentage) / 100}
									claimed={`${formatNumberDownRound(
										Number(allocation?.claimedAmount) | 0,
										tokenDecimals
									)} ${tokenSymbol}`}
									time={allocation.timestamp}
									onClaim={() => onClaim(Number(allocation.no))}
									status={allocation.status}
									layout={layout}
								/>
							</Col>
						)
					)}
				</Row>
			)}
		</div>
	);
};
export default SolLaunchpadDetailAllocation;
