import { useState } from 'react';
import SolButton from '../../../atoms/button';
import SolModal from '../../../atoms/modal';
import './index.scss';

type SolYourWalletDialogProps = {
	show?: boolean;
	walletAddress: string;
	walletUrl?: string;
	onClose?: () => void;
};

const SolYourWalletDialog = ({
	show,
	walletAddress,
	walletUrl,
	onClose
}: SolYourWalletDialogProps) => {
	const [copied, setCopied] = useState(false);

	const handleViewOnBSCScan = () => {
		window.open(walletUrl, '_blank');
	};

	const handleCopyAddress = () => {
		navigator.clipboard.writeText(walletAddress);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	return (
		<SolModal
			show={show}
			className="sol-your-wallet-dialog"
			title="Your wallet"
			onClose={onClose}
		>
			<div className="sol-wallet-info">
				<div className="sol-wallet-address">{walletAddress}</div>
				<div className="sol-wallet-message">
					{copied ? 'Copied successfully!' : <></>}
				</div>
			</div>
			<div className="sol-wallet-action">
				<SolButton onClick={handleViewOnBSCScan}>View on Solana</SolButton>
				<SolButton disabled={copied} onClick={handleCopyAddress}>Copy address</SolButton>
			</div>
		</SolModal>
	);
};
export default SolYourWalletDialog;
