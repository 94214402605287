import { put, takeLatest } from 'redux-saga/effects';
import { getLotteryDetailById } from '../../../redux/services/lottery';
import {
	getLotteryDetail,
	getLotteryDetailFail,
	getLotteryDetailSuccess
} from './actions';
import { LotteryListItem } from '../../../types/lottery.type';

function* handleGetLotteryDetail(action: ReturnType<typeof getLotteryDetail>) {
	try {
		const data: LotteryListItem = yield getLotteryDetailById(action.payload);
		// debugger
		yield put(getLotteryDetailSuccess(data));
	} catch (error) {
		yield put(getLotteryDetailFail());
		console.error('Error fetching data:', error);
	}
}

function* lotteryDetailSaga() {
	yield takeLatest(getLotteryDetail.type, handleGetLotteryDetail);
}

export default lotteryDetailSaga;
