import { createReducer } from '@reduxjs/toolkit';
import {
    claimToken,
    claimTokenFail,
    claimTokenSuccess,
    getLotteryDetail,
    getLotteryDetailFail,
    getLotteryDetailSuccess,
    getWalletInfor,
    getWalletInforFail,
    getWalletInforSuccess,
    joinPool,
    joinPoolFail,
    joinPoolSuccess
} from './actions';
import { LotteryDetailState } from './types';

const initialState: LotteryDetailState = {
	lottery: undefined,
	walletInfor: undefined,
	isLoading: false,
	isJoinPoolSuccess: false,
	isClaimTokenSuccess: false,
	transaction: '',
	isLoadingTransaction: false
};

const lotteryDetailReducer = createReducer(initialState, builder => {
	builder
		.addCase(getLotteryDetail, (state, action) => {
			state.isLoading = true;
		})
		.addCase(
			getLotteryDetailSuccess,
			(state: LotteryDetailState, action) => {
				state.isLoading = true;
				state.lottery = action.payload;
			}
		)
		.addCase(getLotteryDetailFail, (state: LotteryDetailState, action) => {
			state.isLoading = true;
		})

		.addCase(getWalletInfor, (state, action) => {
			state.isLoading = true;
		})
		.addCase(getWalletInforSuccess, (state: LotteryDetailState, action) => {
			state.isLoading = true;
			state.walletInfor = action.payload;
		})
		.addCase(getWalletInforFail, (state: LotteryDetailState, action) => {
			state.isLoading = true;
		})

		.addCase(joinPool, (state, action) => {
			state.isLoadingTransaction = true;
		})
		.addCase(joinPoolSuccess, (state: LotteryDetailState, action) => {
			state.isLoadingTransaction = false;
			state.isJoinPoolSuccess = true;
			state.transaction = action.payload;
		})
		.addCase(joinPoolFail, (state: LotteryDetailState, action) => {
			state.isLoadingTransaction = false;
			state.isJoinPoolSuccess = false;
			state.transaction = '';
		})

		.addCase(claimToken, (state, action) => {
			state.isLoadingTransaction = true;
		})
		.addCase(claimTokenSuccess, (state: LotteryDetailState, action) => {
			state.isLoadingTransaction = false;
			state.isClaimTokenSuccess = true;
			state.transaction = action.payload;
		})
		.addCase(claimTokenFail, (state: LotteryDetailState, action) => {
			state.isLoadingTransaction = false;
			state.isClaimTokenSuccess = false;
			state.transaction = '';
		});
});

export default lotteryDetailReducer;
