import * as solanaWeb3 from '@solana/web3.js';

const dev_config = {
	SOLANA_RPC:
		process.env.REACT_APP_SOLANA_RPC || solanaWeb3.clusterApiUrl('devnet'),
	NETWORK_TYPE: 'devnet',
	SOLANA_EXPLORER: 'https://explorer.solana.com',
	SOLVPAD_TOKEN_MINT: 'Hv6634qu7ucXkaHDgcH3H5fUH1grmSNwpspYdCkSG7hK',
	SUPPORT_TOKEN: {
		USDC: 'BUJST4dk6fnM5G3FnhTVc3pjxRJE7w2C5YL9XgLbdsXW',
		SOL: '11111111111111111111111111111111'
	}
};
const prod_config = {
	SOLANA_RPC:
		process.env.REACT_APP_SOLANA_RPC ||
		solanaWeb3.clusterApiUrl('mainnet-beta'),
	NETWORK_TYPE: 'mainnet',
	SOLANA_EXPLORER: 'https://explorer.solana.com',
	SOLVPAD_TOKEN_MINT: 'Hv6634qu7ucXkaHDgcH3H5fUH1grmSNwpspYdCkSG7hK',
	SUPPORT_TOKEN: {
		USDC: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
		SOL: '11111111111111111111111111111111'
	}
};

export const BASE_URL = process.env.REACT_APP_BASE_URL_API;

export const config =
	process.env.REACT_APP_NETWORK === 'Mainnet' ? prod_config : dev_config;

export const opts = {
	preflightCommitment: 'processed',
	commitment: 'processed'
} as solanaWeb3.ConfirmOptions;
