import { motion } from 'framer-motion';
import { Col, Row } from 'react-bootstrap';
import SolPageTitle from '../../../molecules/page-title';
import './index.scss';

type Props = {
	title?: string;
	description?: string;
	actions?: JSX.Element;
	image?: string;
};

const SolDashboardBanner = ({ title, description, actions, image }: Props) => {
	return (
		<div className="sol-dashboard-banner">
			<Row className="align-items-center gy-4">
				<Col lg="6" className="order-2 order-lg-1">
						<SolPageTitle>{title}</SolPageTitle>
						<motion.div
							initial={{ opacity: 0, y: 30 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, x: 200 }}
							transition={{ duration: 1 }}
							className="sol-dashboard-banner-description"
						>
							{description}
						</motion.div>
						<motion.div
							initial={{ opacity: 0, y: 30 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, x: 200 }}
							transition={{ duration: 1 }}
							className="sol-dashboard-banner-action"
						>
							{actions}
						</motion.div>
				</Col>
				<Col lg="6" className="order-1 order-lg-2 text-center">
					<motion.img
						initial={{ opacity: 0, x: 150 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: 200 }}
						transition={{ duration: 1 }}
						src={image}
						className="img-fluid"
						alt=""
					/>
				</Col>
			</Row>
		</div>
	);
};
export default SolDashboardBanner;
