import SolLotteryOpening from '../../../components/organisms/lottery/opening-lotteries';
import { LotteryListItem } from '../../../types/lottery.type';

interface SolLotteryOpeningContainerProps {
	projects: LotteryListItem[];
}

const SolLotteryOpeningContainer = ({
	projects
}: SolLotteryOpeningContainerProps) => {
	return (
		<SolLotteryOpening
			sectionTitle="Opening Lucky Wheel"
			projectData={projects}
		/>
	);
};
export default SolLotteryOpeningContainer;
