import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { solaUtils } from '../services/blockchain';
import { SET_LATEST_BLOCK_NUMBER } from '../redux/application/types';
import useDebounce from './useDebounce';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { config } from '../_config';
import { setSolBal, setUsdcBal } from '../redux/wallet/actions';
import useIsWindowVisible from './useIsWindowVisible';

const delayTime = 30; //seconds
export default function AppUpdater() {
	const dispatch = useDispatch();
	const provider = solaUtils.getConnectionProvider();
	const windowVisible = useIsWindowVisible();
	const [state, setState] = useState({ blockNumber: 0 });
	const { publicKey } = useWallet();

	const blockNumberCallback = useCallback(
		(blockNumber: number) => {
			setState(s => {
				console.log('BlockNumber:', Math.max(blockNumber, s.blockNumber));
				return { blockNumber: Math.max(blockNumber, s.blockNumber) };
			});
		},
		[setState]
	);

	const debouncedState = useDebounce(state, delayTime * 1000);

	useEffect(() => {
		if (!provider) return undefined;
		provider
			.getSlot()
			.then(blockNumberCallback)
			.catch(error => console.error('Failed to get block number', error));
		// const subId = provider.onSlotUpdate((data)=>{
		//  if(data.type !== "completed") return;
		//     blockNumberCallback(data.slot)
		// })
		// provider.removeSlotUpdateListener(subId)
	}, [dispatch, blockNumberCallback, provider, debouncedState]);

	useEffect(() => {
		if (debouncedState.blockNumber === 0) return;
		console.log('BlockNumber latest:', debouncedState.blockNumber);
		dispatch({
			type: SET_LATEST_BLOCK_NUMBER,
			payload: debouncedState.blockNumber
		});
	}, [provider, dispatch, debouncedState.blockNumber]);

	// update sol balance
	useEffect(() => {
		if (publicKey) {
			solaUtils
				.getSolWalletBalance(publicKey)
				.then(bal => {
					dispatch(setSolBal(bal));
				})
				.catch((err: any) => {
					console.log('errrr get sol bal', err);
				});
		}
	}, [
		provider,
		windowVisible,
		dispatch,
		debouncedState.blockNumber,
		publicKey
	]);

	useEffect(() => {
		if (publicKey) {
			solaUtils
				.getBalanceOfToken(new PublicKey(config.SUPPORT_TOKEN.USDC), publicKey)
				.then(bal => {
					dispatch(setUsdcBal(bal));
				});
		}
	}, [
		provider,
		windowVisible,
		dispatch,
		debouncedState.blockNumber,
		publicKey
	]);

	return null;
}
