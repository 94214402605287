export const STAKE_DEPOSITE_ACTION_REQUEST = 'STAKE_DEPOSITE_ACTION_REQUEST';
export const STAKE_DEPOSITE_ACTION_SUCCESS = 'STAKE_DEPOSITE_ACTION_SUCCESS';
export const STAKE_DEPOSITE_ACTION_FAIL = 'STAKE_DEPOSITE_ACTION_FAIL';


export const UNSTAKE_INIT_ACTION_REQUEST = 'UNSTAKE_INIT_ACTION_REQUEST';
export const UNSTAKE_INIT_ACTION_SUCCESS = 'UNSTAKE_INIT_ACTION_SUCCESS';
export const UNSTAKE_INIT_ACTION_FAIL = 'UNSTAKE_INIT_ACTION_FAIL';

export const WITHDRAW_ACTION_REQUEST = 'WITHDRAW_ACTION_REQUEST';
export const WITHDRAW_ACTION_SUCCESS = 'WITHDRAW_ACTION_SUCCESS';
export const WITHDRAW_ACTION_FAIL = 'WITHDRAW_ACTION_FAIL';

export const CHECK_VALID_NEXT_ACTION_REQUEST = 'CHECK_VALID_NEXT_ACTION_REQUEST';
export const CHECK_PAUSE_ACTION_REQUEST = 'CHECK_PAUSE_ACTION_REQUEST';

export const GET_STAKE_DETAIL_ACTION_REQUEST =
	'GET_STAKE_DETAIL_ACTION_REQUEST';
export const GET_STAKE_DETAIL_ACTION_SUCCESS =
	'GET_STAKE_DETAIL_ACTION_SUCCESS';
export const GET_STAKE_DETAIL_ACTION_FAIL = 'GET_STAKE_DETAIL_ACTION_FAIL';

export const GET_BALANCE_VALUE_ACTION_REQUEST =
	'GET_BALANCE_VALUE_ACTION_REQUEST';
export const GET_BALANCE_VALUE_ACTION_SUCCESS =
	'GET_BALANCE_VALUE_ACTION_SUCCESS';
export const GET_BALANCE_VALUE_ACTION_FAIL = 'GET_BALANCE_VALUE_ACTION_FAIL';

export interface StakingState {
	isLoading: boolean;
	transaction: string;
	isLoadingTransaction: boolean;
	stakeDetail: {
		staked: number;
		unstaked: number;
		reward: number;
        withdrawTimestamp: number;
	};
    currentBalanceValue: string | number;
    isValidNext: boolean;
    isPause: boolean;
}
