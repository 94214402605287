import {
	useAnchorWallet,
	useConnection,
	useWallet
} from '@solana/wallet-adapter-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SolLaunchpadDetailJoinPoolDialog from '../../../components/organisms/launchpad-detail/join-pool-dialog';
import SolLaunchpadDetailPoolCard from '../../../components/organisms/launchpad-detail/pool-card';
import { toggleConnectWallet } from '../../../redux/application/actions';
import { AppState } from '../../../redux/rootReducer';
import { idoService } from '../../../services/blockchain';
import { formatNumberDownRound } from '../../../services/helpers/helpers';
import { JoinIdoParams } from '../../../types/ido.type';
import { getLaunchpadDetail, getWalletInfor } from '../redux/actions';
import { PublicKey } from '@solana/web3.js';
import { useSolBalance } from '../../../hooks/useState';

const SolLaunchpadDetailPoolCardContainer: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const solBal = useSolBalance();
	const [showJoinModal, setShowJoinModal] = useState<boolean>(false);

	const walletInfo = useSelector(
		(state: AppState) => state.launchpadDetail.walletInfor
	);

	const projectSelected = useSelector(
		(state: AppState) => state.launchpadDetail.launchpad
	);

	const [enableJoinBtn, setEnableJoinBtn] = useState<boolean>(false);
	const { publicKey, connected } = useWallet();

	useEffect(() => {
		if (!projectSelected?.contract || !walletInfo) return;

		setEnableJoinBtn(true);

		console.log('================', projectSelected);

		if (
			projectSelected?.state === 'P' ||
			projectSelected?.state === 'C' ||
			projectSelected.contract === 'TBA'
		) {
			console.log('111111');
			setEnableJoinBtn(false);
			return;
		}

		if (
			!walletInfo?.remainingAllocation ||
			walletInfo?.remainingAllocation === '0'
		) {
			console.log('22222222', walletInfo);
			setEnableJoinBtn(false);
			return;
		}

		if (!(walletInfo.roundState === 1 || walletInfo.roundState === 3)) {
			console.log('333333');
			setEnableJoinBtn(false);
			return;
		}
	}, [projectSelected, walletInfo]);

	useEffect(() => {
		if (connected) {
			dispatch(
				getWalletInfor({
					projectContract: projectSelected?.contract,
					publicKey: publicKey
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectSelected, connected]);

	const handleShowConnectWallet: VoidFunction = () => {
		dispatch(toggleConnectWallet(true));
	};

	return (
		<>
			<SolLaunchpadDetailPoolCard
				enableJoin={enableJoinBtn}
				opening
				onJoinPool={() => setShowJoinModal(true)}
				// onApprove={() => setShowApproveModal(true)}
				onConnectWallet={() => handleShowConnectWallet()}
			/>
			<SolLaunchpadDetailJoinPoolDialog
				show={showJoinModal}
				projectName={projectSelected?.name || ''}
				amountSymbol={projectSelected?.symbol}
				balance={
					projectSelected?.raiseToken === PublicKey.default.toString()
						? formatNumberDownRound(
								Number(solBal) >= Number(walletInfo?.remainingAllocation)
									? Number(walletInfo?.remainingAllocation)
									: Number(solBal),
								projectSelected?.raiseTokenDecimals
						  )
						: formatNumberDownRound(
								Number(walletInfo?.tokenBalance) >=
									Number(walletInfo?.remainingAllocation)
									? Number(walletInfo?.remainingAllocation)
									: Number(walletInfo?.tokenBalance),
								projectSelected?.raiseTokenDecimals
						  )
				}
				onClose={() => setShowJoinModal(false)}
				// onJoin={handleJoinPool}
			/>
		</>
	);
};
export default SolLaunchpadDetailPoolCardContainer;
