import { Button, ButtonProps } from 'react-bootstrap';
import './index.scss';

interface SolButtonProps extends ButtonProps {
	outline?: boolean;
};

const SolButton: React.FC<SolButtonProps> = ({
	outline = true,
	...props
}: SolButtonProps) => {
	return (
		<Button
			variant={outline ? 'outline-success' : 'success'}
			{...props}
			className={`sol-btn ${props.className}`}
		>
			{props.children}
		</Button>
	);
};
export default SolButton;
