import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { get, intersection } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { config } from '../../../../_config';
import {
	GAME_TYPES,
	LUCKY_WHEEL_STATUS,
	LUCKY_WHEEL_STATUS_LABEL,
	LUCKY_WHEEL_STATUS_REVERT,
	MODAL_PROCESS_STATUS
} from '../../../../constants';
import {
	useLotteryDetail,
	useLotteryProgram
} from '../../../../hooks/useLottery';
import { useSolBalance } from '../../../../hooks/useState';
import { getLotteryDetail } from '../../../../pages/lottery-detail/redux/actions';
import { solaUtils } from '../../../../services/blockchain';
import { lotteryService } from '../../../../services/blockchain/lottery.service';
import { lotteryWeb3 } from '../../../../services/blockchain/lottery.web3';
import {
	findNewNumber,
	formatNumberDownRound
} from '../../../../services/helpers';
import SolButton from '../../../atoms/button';
import SolProgressBar from '../../../molecules/progress-bar';
import SolBuyTicketDialog from '../../common/buy-ticket-processing-dialog';
import './index.scss';
export interface ISolLotteryInfo {
	image: string;
	name: string;
	price: string;
	description: string;
	countdown: number;
	ticketPrice: string;
	maxTicketPerUser: number;
	totalTicket: number;
	reward: number | string;
	active: boolean;
	totalTicketReward: number;
}
interface ISolLotteryInfoProps {
	userLotteryInfo: any;
	setUserLotteryInfo: any;
}

const SolLotteryInfo = ({
	userLotteryInfo,
	setUserLotteryInfo
}: ISolLotteryInfoProps) => {
	const params = useParams();
	const dispatch = useDispatch();
	const wallet = useAnchorWallet();
	const solBalance = useSolBalance();
	const lotteryDetail: any = useLotteryDetail();
	const lotteryProgram = useLotteryProgram();

	// buy ticket
	const [visible, setVisible] = useState(false);
	const [status, setStatus] = useState<any>(MODAL_PROCESS_STATUS.LOADING);
	const [error, setError] = useState<any>('');
	const [txHash, setTxHash] = useState<any>('');
	const [sportNumber, setSportNumber] = useState('');
	const [isWinner, setIsWinner] = useState(false);
	const [buyEnable, setBuyEnable] = useState(false);
	const oldNumber = useRef('');

	// reward
	const [rewardVisible, setRewardVisible] = useState(false);

	// refund
	const [refundVisible, setRefundVisible] = useState(false);

	useEffect(() => {
		if (wallet && lotteryProgram && lotteryDetail?.gameId) {
			handleGetUserWalletInfo();
		}
	}, [wallet, lotteryProgram, lotteryDetail?.gameId]);

	const checkIsWinner = (userNumber: any[], gameNumber: any[]) => {
		console.log('userNumber', userNumber?.toString());
		console.log('gameNumber', gameNumber?.toString());
		const userNoArr = userNumber?.toString()?.split(',');
		const gameNoArr = gameNumber?.toString()?.split(',');
		if (
			userNoArr?.length > 0 &&
			gameNoArr?.length > 0 &&
			gameNoArr?.length > 0 &&
			userNumber?.length > 0
		) {
			const temp = intersection(userNoArr, gameNoArr);
			if (temp.length > 0) {
				setIsWinner(true);
			} else {
				setIsWinner(false);
			}
		} else {
			setIsWinner(false);
		}
	};

	useEffect(() => {
		if (wallet?.publicKey && lotteryDetail) {
			if (userLotteryInfo) {
				console.log(
					'akhsakjsasasas',
					get(userLotteryInfo, 'spotNumbers', '').length,
					get(lotteryDetail, 'maxTicketPerUser', 100)
				);
				if (
					get(userLotteryInfo, 'spotNumbers', '').length >=
					get(lotteryDetail, 'maxTicketPerUser', 100)
				) {
					setBuyEnable(false);
				} else {
					checkBuyEnable();
				}
			} else {
				checkBuyEnable();
			}
		}
	}, [userLotteryInfo, lotteryDetail, wallet]);

	const checkBuyEnable = async () => {
		try {
			if (wallet?.publicKey) {
				if (lotteryDetail?.currency?.toString() === config.SUPPORT_TOKEN.SOL) {
					if (
						Number(solBalance) >=
						get(lotteryDetail, 'priceTicket', 0) / 100
					) {
						setBuyEnable(true);
					} else {
						setBuyEnable(false);
					}
				} else {
					const bal = await solaUtils.getBalanceOfToken(
						new PublicKey(
							get(lotteryDetail, 'currency', config.SUPPORT_TOKEN.USDC)
						),
						wallet?.publicKey
					);
					console.log('akjsgajhgsajhsas', bal);
					if (Number(bal) > get(lotteryDetail, 'priceTicket', 0) / 100) {
						setBuyEnable(true);
					} else {
						setBuyEnable(false);
					}
				}
			}
		} catch (error) {}
	};

	const handleGetUserWalletInfo = async () => {
		try {
			if (lotteryProgram && wallet) {
				const res = await lotteryWeb3.getPlayerInfo(
					new PublicKey(lotteryProgram),
					wallet?.publicKey,
					get(lotteryDetail, 'gameId', 0)
				);
				console.log('handleGetUserWalletInfo', res);
				setUserLotteryInfo(res);
				checkIsWinner(res?.spotNumbers, lotteryDetail?.spotWinners);
				oldNumber.current = get(res, 'spotNumbers', '').toString();
			}
		} catch (error) {
			setUserLotteryInfo(null);
		}
	};

	// Renderer callback with condition
	const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
		if (completed) {
			// Render a complete state
			// return <div className="end">Ended</div>;
			<></>;
		} else {
			// Render a countdown
			return (
				<div className="countdown">
					<div className="item">
						<b>{days}</b>
						<span>DAYS</span>
					</div>
					<div className="item">
						<b>{hours}</b>
						<span>HOURS</span>
					</div>
					<div className="item">
						<b>{minutes}</b>
						<span>MINS</span>
					</div>
					<div className="item">
						<b>{seconds}</b>
						<span>SECS</span>
					</div>
				</div>
			);
		}
	};

	const handleBuyLotteryTicket = async () => {
		try {
			if (wallet && lotteryDetail && lotteryProgram) {
				setVisible(true);
				const res = await lotteryService.joinLottery(wallet, {
					programID:
						typeof lotteryDetail?.programId === 'string'
							? new PublicKey(lotteryDetail?.programId)
							: lotteryDetail?.programId,
					currency:
						typeof lotteryDetail?.currency === 'string'
							? new PublicKey(lotteryDetail?.currency)
							: lotteryDetail?.currency,
					id: get(lotteryDetail, 'gameId', 0)
				});
				console.log('handleBuyTicket', res);
				if (res?.status) {
					const id = params.id;
					if (!id) return;
					const resp = await lotteryWeb3.getPlayerInfo(
						new PublicKey(lotteryProgram),
						wallet?.publicKey,
						get(lotteryDetail, 'gameId', 0)
					);
					checkIsWinner(resp?.spotNumbers, lotteryDetail?.spotWinners);
					const currentNumber = get(resp, 'spotNumbers', '').toString();
					console.log('currentNumber', currentNumber);
					console.log('oldNumber', oldNumber.current);
					const newNumber = findNewNumber(oldNumber.current, currentNumber);
					console.log('new Number', newNumber);
					setSportNumber(newNumber);
					oldNumber.current = currentNumber;
					setUserLotteryInfo(resp);
					setStatus(MODAL_PROCESS_STATUS.DONE);
					setTxHash(res?.data);
					dispatch(getLotteryDetail(id));
				} else {
					setStatus(MODAL_PROCESS_STATUS.REJECTED);
					setError(res?.message);
				}
			}
		} catch (error) {}
	};

	const handleClaimReward = async () => {
		try {
			if (wallet && lotteryDetail && lotteryProgram) {
				setRewardVisible(true);
				const res = await lotteryService.claimReward(wallet, {
					programID: new PublicKey(lotteryProgram),
					rewardCurrency: lotteryDetail?.rewardCurrency,
					id: lotteryDetail?.gameId
				});
				console.log('handleClaimReward', res);
				if (res?.status) {
					const id = params.id;
					if (!id) return;
					setStatus(MODAL_PROCESS_STATUS.DONE);
					setTxHash(res?.data);
					dispatch(getLotteryDetail(id));
					handleGetUserWalletInfo();
				} else {
					setStatus(MODAL_PROCESS_STATUS.REJECTED);
					setError(res?.message);
				}
			}
		} catch (error) {
			console.log('errrrrr', error);
		}
	};

	const handleRefund = async () => {
		try {
			if (wallet && lotteryDetail && lotteryProgram) {
				setRefundVisible(true);
				const res = await lotteryService.handleRefund(wallet, {
					programID: new PublicKey(lotteryProgram),
					currency: lotteryDetail?.currency,
					gameId: lotteryDetail?.gameId
				});
				console.log('handleRefund', res);
				if (res?.status) {
					const id = params.id;
					if (!id) return;
					setStatus(MODAL_PROCESS_STATUS.DONE);
					setTxHash(res?.data);
					dispatch(getLotteryDetail(id));
					handleGetUserWalletInfo();
				} else {
					setStatus(MODAL_PROCESS_STATUS.REJECTED);
					setError(res?.message);
				}
			}
		} catch (error) {}
	};

	const onCountdownComplete = () => {
		const id = params.id;
		if (!id) return;
		dispatch(getLotteryDetail(id));
	};

	const handleResetState = () => {
		setTxHash('');
		setStatus(MODAL_PROCESS_STATUS.LOADING);
		setError('');
		setVisible(false);
		setRewardVisible(false);
		setRefundVisible(false);
		setSportNumber('');
	};

	return (
		<div className="sol-lottery-info">
			<div className="image">
				<img
					src={get(GAME_TYPES, `[${lotteryDetail?.type}].src`, '')}
					alt={get(GAME_TYPES, `[${lotteryDetail?.type}].name`, '')}
				/>
			</div>
			<div style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', top: '-50px' }}>
				<div
					className={`information-state ribbon ${lotteryDetail?.type}`}
					title={LUCKY_WHEEL_STATUS_LABEL[lotteryDetail?.state]}
				>
					{isWinner ? 'Winner' : LUCKY_WHEEL_STATUS_LABEL[lotteryDetail?.state]}
				</div>
			</div>

			<div className={`name ${lotteryDetail?.type}`}>
				{get(GAME_TYPES, `[${lotteryDetail?.type}].name`, '')}
			</div>
			<div className={`price ${lotteryDetail?.type}`}>
				{formatNumberDownRound(
					lotteryDetail?.prize / 10 ** lotteryDetail?.rewardCurrencyDecimal,
					0
				)}{' '}
				{lotteryDetail?.rewardCurrencySymbol}
			</div>
			<div className="description">{get(lotteryDetail, 'description', '')}</div>
			{get(lotteryDetail, 'state', '') === LUCKY_WHEEL_STATUS.COMPLETED &&
				get(lotteryDetail, 'spotWinners', ['1']).length === 0 && (
					<div className="state-drawing">Waiting for drawing</div>
				)}
			{!['RF', 'C', 'RA'].includes(get(lotteryDetail, 'state', 'U')) && (
				<div className={`state ${get(lotteryDetail, 'state', 'U')}`}>
					{LUCKY_WHEEL_STATUS_REVERT[get(lotteryDetail, 'state', 'U')]}
				</div>
			)}

			{lotteryDetail?.state === LUCKY_WHEEL_STATUS.OPENING ? (
				<Countdown
					date={new Date(lotteryDetail?.endTimestamp?.toString() * 1000)}
					renderer={renderer}
					onComplete={onCountdownComplete}
				/>
			) : lotteryDetail?.state === LUCKY_WHEEL_STATUS.UPCOMING ? (
				<Countdown
					date={new Date(lotteryDetail?.openTimestamp?.toString() * 1000)}
					renderer={renderer}
					onComplete={onCountdownComplete}
				/>
			) : (
				<></>
			)}
			{lotteryDetail?.state === LUCKY_WHEEL_STATUS.OPENING && (
				<div className="action">
					<SolButton
						disabled={!buyEnable}
						onClick={handleBuyLotteryTicket}
						variant="success"
					>
						Buy Now
					</SolButton>
				</div>
			)}
			{lotteryDetail?.state === LUCKY_WHEEL_STATUS.REFUND && (
				<div className="action">
					<SolButton
						disabled={
							!(
								get(userLotteryInfo, 'spotNumbers', '').toString() &&
								userLotteryInfo?.amountParticipated?.toString() > 0 &&
								!get(userLotteryInfo, 'isRefund', true)
							)
						}
						onClick={handleRefund}
						variant="success"
					>
						Refund
					</SolButton>
				</div>
			)}
			{lotteryDetail?.state === LUCKY_WHEEL_STATUS.REWARD_ACTIVE && (
				<div className="action">
					<SolButton
						disabled={
							!(
								get(userLotteryInfo, 'spotNumbers', '').toString() &&
								isWinner &&
								(userLotteryInfo?.amountClaimed?.toString() === 0 ||
									userLotteryInfo?.amountClaimed?.toString() === '0')
							)
						}
						onClick={handleClaimReward}
						variant="success"
					>
						Claim Reward
					</SolButton>
				</div>
			)}
			{/* {userLotteryInfo && (
				<div className="information">
					<h4>Your ticket</h4>
					<ul>
						<li>
							<span>Spot number</span>
							<span>{get(userLotteryInfo, 'spotNumbers', '').toString()}</span>
						</li>
					</ul>
				</div>
			)} */}
			<div className="information">
				<h4>Ticket information</h4>
				<ul>
					<li>
						<span>Price</span>
						<span>
							{get(lotteryDetail, 'priceTicket', 0) / 100}{' '}
							{get(lotteryDetail, 'currencySymbol', 'USDT')}
						</span>
					</li>
					<li>
						<span>Max Ticket Per User</span>
						<span>{get(lotteryDetail, 'maxTicketPerUser', 0)}</span>
					</li>
					<li>
						<span>User participated</span>
						<span>{get(lotteryDetail, 'participatedCount', 0)}</span>
					</li>
					<li>
						<span>Participated ticket </span>
						<span>{get(lotteryDetail, 'totalTicket', 0)}</span>
					</li>
					<li>
						<span>Max Ticket</span>
						<span>{get(lotteryDetail, 'maxTicket', 0)}</span>
					</li>
					<li>
						<span>Progress</span>
						<span>
							{parseFloat(
								(
									Math.round(
										(get(lotteryDetail, 'totalTicket', 0) /
											get(lotteryDetail, 'maxTicket', 1)) *
											10000
									) / 100
								)
									.toFixed(2)
									.toString()
							)}
							{/* {formatNumberDownRound(
								(get(lotteryDetail, 'totalTicket', 0) * 100) /
									get(lotteryDetail, 'maxTicket', 1),
								0
							)} */}
							%
						</span>
					</li>
					<SolProgressBar
						percent={parseFloat(
							(
								Math.round(
									(get(lotteryDetail, 'totalTicket', 0) /
										get(lotteryDetail, 'maxTicket', 1)) *
										10000
								) / 100
							)
								.toFixed(2)
								.toString()
						)}
					/>
				</ul>
			</div>
			<div className="information mb-0">
				<h4>Reward information</h4>

				<ul>
					<li>
						<span>Prize</span>
						<span>
							{formatNumberDownRound(
								String(
									get(lotteryDetail, 'prize', 0) /
										10 ** lotteryDetail?.rewardCurrencyDecimal
								),
								0
							)}{' '}
							{lotteryDetail?.rewardCurrencySymbol}
						</span>
					</li>
					<li>
						<span>Max Prize</span>
						<span>
							{formatNumberDownRound(
								String(
									(get(lotteryDetail, 'prize', 0) *
										get(lotteryDetail, 'maxWinner', 1)) /
										10 ** get(lotteryDetail, 'rewardCurrencyDecimal', 9)
								),
								0
							)}{' '}
							{lotteryDetail?.rewardCurrencySymbol}
						</span>
					</li>
					<li>
						<span>Number Winner</span>
						<span>{get(lotteryDetail, 'maxWinner', 1)}</span>
					</li>
				</ul>
			</div>
			<SolBuyTicketDialog
				processTitle="Buying Ticket"
				show={visible}
				status={status}
				error={error}
				txHash={txHash}
				onClose={handleResetState}
				sportNumber={sportNumber}
			/>
			<SolBuyTicketDialog
				processTitle="Claim Reward"
				show={rewardVisible}
				status={status}
				error={error}
				txHash={txHash}
				onClose={handleResetState}
			/>
			<SolBuyTicketDialog
				processTitle="Refund"
				show={refundVisible}
				status={status}
				error={error}
				txHash={txHash}
				onClose={handleResetState}
			/>
		</div>
	);
};
export default SolLotteryInfo;
