import React, { ReactElement } from 'react'
import './index.scss';
import { Col, Row } from 'react-bootstrap';

interface ISolTokenMintingTemplateProps {
  header: ReactElement
  tokenInfor: ReactElement
  preview: ReactElement
}

const SolTokenMintingTemplate: React.FC<ISolTokenMintingTemplateProps> = ({
  header,
  tokenInfor,
  preview
} : ISolTokenMintingTemplateProps) => {
  return (
    <div className="sol-token-template">
      {header}

      <div className='sol-token-template-main'>
          <Row>
            <Col>
              {tokenInfor}
            </Col>
            <Col>
              {preview}
            </Col>
          </Row>
      </div>
    </div>
  )
}

export default SolTokenMintingTemplate