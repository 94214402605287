// store.js
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootReducers from './rootReducer';
import rootSaga from './rootSaga';
import {
	GET_LOTTERY_DETAIL_ACTION_SUCCESS,
	GET_WALLET_INFOR_ACTION_REQUEST,
	JOIN_POOL_ACTION_REQUEST
} from '../pages/lottery-detail/redux/types';
import { GET_LOTTERIES_ACTION_SUCCESS } from '../pages/lottery/redux/types';

let sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
	reducer: rootReducers,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these action types
				ignoredActions: [
					GET_LOTTERY_DETAIL_ACTION_SUCCESS,
					GET_LOTTERIES_ACTION_SUCCESS,
					GET_WALLET_INFOR_ACTION_REQUEST,
					JOIN_POOL_ACTION_REQUEST
				],
				// // Ignore these field paths in all actions
				// ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
				// // Ignore these paths in the state
				ignoredPaths: ['lotteryDetail', 'lottery']
			}
		})
			.prepend(sagaMiddleware)
			.concat(logger)
	// middleware: (getDefaultMiddleware) =>

	//   getDefaultMiddleware().concat(middleware),
});

sagaMiddleware.run(rootSaga);
