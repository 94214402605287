import Modal from 'react-bootstrap/Modal';
import './index.scss';

const SolModal = ({
	show,
	className,
	size = 'sm',
	title,
	children,
	actions,
	onClose,
	closeButton = true
}: any) => {
	return (
		<Modal
			className={`sol-modal ${className}`}
			show={show}
			onHide={onClose}
			size={size}
			keyboard={false}
			backdrop="static"
		>
			<Modal.Header closeButton={closeButton}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			{actions ? <Modal.Footer>{actions}</Modal.Footer> : <></>}
		</Modal>
	);
};
export default SolModal;
