import './index.scss';
import { motion } from 'framer-motion';

type Props = {
	image?: string;
	title?: string;
	description?: string;
	url?: string;
};

const SolDashboardNewsItemLg = ({ image, title, description, url }: Props) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: -150 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 200 }}
			transition={{ duration: 1 }}
			className="sol-dashboard-news-lg"
			style={{ backgroundImage: `url(${image})` }}
		>
			<div className="sol-dashboard-news-lg-body">
				<a
					href={url}
					target="_blank"
					className="sol-dashboard-news-lg-title"
					rel="noreferrer"
				>
					{title}
				</a>
				<div className="sol-dashboard-news-lg-description">{description}</div>
			</div>
		</motion.div>
	);
};
export default SolDashboardNewsItemLg;
