import { get } from 'lodash';
import { useLotteryDetail } from '../../../../hooks/useLottery';
import SolTextRunnint from '../text-running';
import './index.scss';

const SolWinLotteries = () => {
	const lotteryDetail = useLotteryDetail();
	const spotWinner = get(lotteryDetail, 'spotWinners', []);
	if (spotWinner.length === 0) return null;
	return (
		<div className="sol-win-lotteries">
			<span className="sol-win-lotteries-label">Lucky Number:</span>
			<SolTextRunnint text={spotWinner.toString().replaceAll(',', ` - `)} />
		</div>
	);
};

export default SolWinLotteries;
