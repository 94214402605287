import { BN } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';

const PLAYER_ACCOUNT = 'PLAYER';
const GALAKI_WINNER = 'GALAKI_WINNER';
const WHITE_LIST = 'WHITE_LIST';

class LotteryFindPDA {
	getPlayerPda = (
		programId: PublicKey,
		lotteryPda: PublicKey,
		user: PublicKey
	) => {
		const [mint, _] = PublicKey.findProgramAddressSync(
			[Buffer.from(PLAYER_ACCOUNT), lotteryPda.toBuffer(), user.toBuffer()],
			programId
		);
		console.log('player account PDA', mint.toString());
		return mint;
	};

	getLotteryPda = (programId: PublicKey, id: number) => {
		const [mint, _] = PublicKey.findProgramAddressSync(
			[Buffer.from(GALAKI_WINNER), new BN(id).toArrayLike(Buffer, 'be', 4)],
			programId
		);
		console.log('Galaki PDA', mint.toString());
		return mint;
	};

	getWhiteListPda = (programId: PublicKey, lotteryPda: PublicKey) => {
		const [mint, _] = PublicKey.findProgramAddressSync(
			[Buffer.from(WHITE_LIST), lotteryPda.toBuffer()],
			programId
		);
		return mint;
	};
}

export const lotteryFindPda = new LotteryFindPDA();
