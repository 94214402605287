import { motion } from 'framer-motion';
import './index.scss';

type Props = {
	image?: string;
	title?: string;
	url?: string;
	className?: string;
};

const SolDashboardNewsItemMd = ({ image, title, url, className }: Props) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: 150 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 200 }}
			transition={{ duration: 1 }}
			className={`sol-dashboard-news-md ${className}`}
		>
			<a
				href={url}
				target="_blank"
				className="sol-dashboard-news-md-title"
				rel="noreferrer"
			>
				{title}
			</a>
			<img src={image} alt="" />
		</motion.div>
	);
};
export default SolDashboardNewsItemMd;
