import SolLotteryTemplate from '../../../components/templates/lottery';
import { LUCKY_WHEEL_STATUS } from '../../../constants';
import { LotteryListItem } from '../../../types/lottery.type';
import SolLotteryCompletedContainer from './completed.container';
import SolLotteryOpeningContainer from './opening.container';
import SolLotteryUpcomingContainer from './upcoming.container';

interface SolLotteryMainContainerProps {
	projects: LotteryListItem[];
}

const SolLotteryMainContainer = ({
	projects
}: SolLotteryMainContainerProps) => {
	return (
		<SolLotteryTemplate
			section1={
				<SolLotteryOpeningContainer
					projects={projects?.filter(
						item => item.state === LUCKY_WHEEL_STATUS.OPENING
					)}
				/>
			}
			section3={
				<SolLotteryCompletedContainer
					projects={projects?.filter(
						item =>
							item?.state === LUCKY_WHEEL_STATUS.COMPLETED ||
							item?.state === LUCKY_WHEEL_STATUS.REFUND ||
							item?.state === LUCKY_WHEEL_STATUS.REWARD_ACTIVE
					)}
				/>
			}
			section2={
				<SolLotteryUpcomingContainer
					projects={projects?.filter(
						item => item?.state === LUCKY_WHEEL_STATUS.UPCOMING
					)}
				/>
			}
		/>
	);
};
export default SolLotteryMainContainer;
