import { ChangeEvent, KeyboardEvent } from 'react';
import './index.scss';

type SolFormGroupProps = {
	label: string;
	leftIcon?: any;
	rightIcon?: any;
	type?: string;
	placeholder?: string;
	value?: string;
	note?: any;
	readOnly?: boolean;
	required?: boolean;
	size?: 'sm' | 'md';
	isTextArea?: boolean;
	onClick?: () => void;
	onBlur?: any;
	onChange?: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	id: string;
	name: string;
};

const SolFormGroup: React.FC<SolFormGroupProps> = ({
	label = '',
	leftIcon = '',
	rightIcon = '',
	type = 'text',
	placeholder = '',
	value = '',
	note = '',
	readOnly = false,
	required = false,
	size = 'md',
	isTextArea = false,
	onClick = () => {},
	onChange = () => {},
	onBlur,
	id,
	name
}: SolFormGroupProps) => {
	return (
		<div className="sol-form-group">
			{label ? <div className="sol-form-label">{label}</div> : <></>}
			<div
				className={`sol-form-control ${leftIcon ? 'with-left-icon' : ''} ${
					rightIcon ? 'with-right-icon' : ''
				}`}
			>
				{leftIcon ? (
					<span
						className={`sol-form-control-icon left ${
							leftIcon === '...' ? 'default' : ''
						}`}
					>
						{leftIcon}
					</span>
				) : (
					<></>
				)}
				{rightIcon ? (
					<span className="sol-form-control-icon right">{rightIcon}</span>
				) : (
					<></>
				)}

				{isTextArea ? (
					<textarea
						name={name}
						id={id}
						rows={3}
						placeholder={placeholder}
						value={value}
						onChange={e => (readOnly ? null : onChange(e))}
					/>
				) : (
					<input
						className={`sol-form-control-input ${size}`}
						type={type}
						placeholder={placeholder}
						value={value}
						readOnly={readOnly}
						required={required}
						onClick={() => (readOnly ? onClick() : null)}
						onChange={e => (readOnly ? null : onChange(e))}
						id={id}
						name={name}
					/>
				)}
			</div>
			{note ? <div className="sol-form-group-note">{note}</div> : <></>}
		</div>
	);
};
export default SolFormGroup;
