import './index.scss';

import { motion, useAnimation, useTime, useTransform } from 'framer-motion';
import { useEffect } from 'react';

interface ISolLoader {
	size?: 'md' | 'lg';
}

const SolLoader: React.FC<ISolLoader> = ({ size = 'md' }: ISolLoader) => {
	const time = useTime();
	const rotate = useTransform(time, [0, 4000], [0, 360], { clamp: false });

	return (
		<div className="sol-loading">
			<motion.img
				src={'/images/logo.svg'}
				alt="Logo"
				className={`sol-loading-logo ${size}`}
				style={{ rotate }}
			/>
			<h1>Comming Soon...</h1>
		</div>
	);
};

export default SolLoader;
