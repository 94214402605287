import { Spinner } from 'react-bootstrap';
import { config } from '../../../../_config';
import { MODAL_PROCESS_STATUS } from '../../../../constants';
import SolButton from '../../../atoms/button';
import SolModal from '../../../atoms/modal';
import './index.scss';
import { zeroPadding } from '../../../../services/helpers';
import SolLotteryMachine from '../../launchpad-detail/lottery-machine';

export const roundCircles: number[] = [1, 2, 1, 1, 2, 1, 2, 1, 2, 2];

export const initialIntervalSpeed: number[] = [
	150, 150, 170, 160, 160, 170, 180, 190, 160, 150
];
export const lastIntervalSpeed: number[] = [
	200, 200, 150, 150, 190, 170, 180, 170, 160, 150
];

type SolBuyTicketDialogProps = {
	show?: boolean;
	txHash?: string;
	onClose?: () => void;
	status?: any;
	processTitle: string;
	error?: string;
	sportNumber?: string;
};

const SolBuyTicketDialog = ({
	show,
	onClose,
	txHash,
	status,
	processTitle,
	error,
	sportNumber = ''
}: SolBuyTicketDialogProps) => {
	const handleViewOnBSCScan = () => {
		window.open(
			`${config.SOLANA_EXPLORER}/tx/${txHash}?cluster=${config.NETWORK_TYPE}`,
			'_blank'
		);
	};

	return (
		<SolModal
			show={show}
			className="sol-your-wallet-dialog"
			title={''}
			// size="lg"
			onClose={onClose}
		>
			<div className={`sol-modal-processing ${status}`}>
				<>
					<div className="sol-modal-processing-header">
						{status === MODAL_PROCESS_STATUS.REJECTED ? (
							<img src="/images/icons/icon-rejected.svg" alt="" />
						) : (
							<></>
						)}

						{status === MODAL_PROCESS_STATUS.DONE ? (
							<img src="/images/icons/icon-success.svg" alt="" />
						) : (
							<></>
						)}

						<h2>
							{!(status === MODAL_PROCESS_STATUS.DONE)
								? String(status).toUpperCase()
								: 'Done'}
						</h2>
					</div>

					<p className="sol-modal-processing-desc">
						{status === MODAL_PROCESS_STATUS.LOADING ? (
							<>
								Your request is being processed. <br /> Please wait...
							</>
						) : (
							''
						)}

						{status === MODAL_PROCESS_STATUS.DONE ? (
							<>
								<>Your request is submitted.</>
								<br />
								{sportNumber && (
									<>
										<div className="mt-2">Your lucky number is</div>
										<div className="d-flex mt-3 align-items-center gap-2 sol-modal-number-machine">
											{zeroPadding(sportNumber)
												?.split('')
												.map(Number)
												.map((item: any, index) => (
													<SolLotteryMachine
														circleRound={roundCircles[index]}
														initialIntervalSpeed={initialIntervalSpeed[index]}
														lastIntervalSpeed={lastIntervalSpeed[index]}
														targetNumber={item}
													/>
												))}
										</div>
									</>
								)}
							</>
						) : (
							''
						)}

						{status === MODAL_PROCESS_STATUS.REJECTED ? (
							<>
								Your request is rejected. Please try again later.
								<br />
								{error && (
									<span className="error">
										<span>{error}</span>
									</span>
								)}
							</>
						) : (
							''
						)}
					</p>

					{status === MODAL_PROCESS_STATUS.LOADING && (
						<Spinner animation="border" />
					)}
					<div className="sol-modal-processing-btns">
						{status === MODAL_PROCESS_STATUS.DONE && (
							<SolButton size="sm" onClick={handleViewOnBSCScan}>
								View on Solana
							</SolButton>
						)}
						{status === MODAL_PROCESS_STATUS.DONE ||
						status === MODAL_PROCESS_STATUS.REJECTED ? (
							<SolButton size="sm" onClick={onClose}>
								Close
							</SolButton>
						) : (
							<></>
						)}
					</div>
				</>
			</div>
			<div className="sol-wallet-action"></div>
		</SolModal>
	);
};
export default SolBuyTicketDialog;
