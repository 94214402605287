import ReactPaginate from 'react-paginate';
import './index.scss';
import { calculatePageCount } from '../../../utils';

interface ISolPaginationProps {
	total?: number;
	activePage?: number;
	pageSize?: number;
	onChange?: (pageNumber: number) => void;
}

const SolPagination = ({
	total = 1,
	activePage = 1,
	pageSize = 10,
	onChange
}: ISolPaginationProps) => {
	const handleChangePage = (pageNumber: number) => {
		if (onChange) onChange(pageNumber + 1);
	};

	return (
		<ReactPaginate
			containerClassName={
				'pagination sol-pagination d-flex align-items-center justify-content-end'
			}
			activeClassName={'active'}
			pageClassName={'page-item'}
			onPageChange={event => handleChangePage(event.selected)}
			breakLabel="..."
			pageCount={calculatePageCount(total, pageSize)}
			initialPage={activePage - 1}
			previousLabel={
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g opacity="0.5">
						<path
							d="M18 21L12 15L18 9"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</svg>
			}
			nextLabel={
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g opacity="0.5">
						<path
							d="M12 21L18 15L12 9"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</svg>
			}
		/>
	);
};
export default SolPagination;
