import SolLotteryCompleted from '../../../components/organisms/lottery/completed-lotteries';
import { LotteryListItem } from '../../../types/lottery.type';

interface SolLotteryCompletedContainerProps {
	projects: LotteryListItem[];
}
const SolLotteryCompletedContainer = ({
	projects
}: SolLotteryCompletedContainerProps) => {
	return (
		<SolLotteryCompleted
			sectionTitle="Completed Lucky Wheel"
			projects={projects}
		/>
	);
};
export default SolLotteryCompletedContainer;
