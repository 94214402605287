import React from 'react';
import SolTokenMintingTemplate from '../../../components/templates/token-minting';
import SolTokenMintingHeader from '../../../components/organisms/token-minting/header';
import SolTokenInfor from '../../../components/organisms/token-minting/token-infor';
import SolTokenPreview from '../../../components/organisms/token-minting/preview';

const SolTokenMintingContainer: React.FC = () => {
	return (
		<SolTokenMintingTemplate
			header={<SolTokenMintingHeader />}
			tokenInfor={<SolTokenInfor />}
            preview={<SolTokenPreview />}
		/>
	);
};

export default SolTokenMintingContainer;
