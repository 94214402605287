import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OverlayLoading from '../../../components/molecules/overlay-loading';
import SolLeadership, {
	ISolLeadership
} from '../../../components/organisms/lottery-detail/leadership';
import SolLotteryInfo, {
	ISolLotteryInfo
} from '../../../components/organisms/lottery-detail/lottery-info';
import SolLotteryDetailTabs from '../../../components/organisms/lottery-detail/tabs';
import SolLotteryDetailTemplate from '../../../components/templates/lottery-detail';
import { AppState } from '../../../redux/rootReducer';
import { TabType } from '../../../types';
import { get } from 'lodash';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { useLotteryDetail } from '../../../hooks/useLottery';
import { GAME_TYPES } from '../../../constants';
import { minimizeAddress } from '../../../services/helpers';
import { Keypair } from '@solana/web3.js';

export const TABS: TabType[] = [
	{ key: 'Recent', text: 'Recent' },
	{ key: 'YourTicket', text: 'Your ticket' }
	// { key: 'HighrollerStars', text: 'Highroller Stars' }
];

const SolLotteryDetailMainContainer: React.FC = () => {
	const anchorWallet = useAnchorWallet();
	const lotteryDetail = useLotteryDetail();

	const [activeTab, setActiveTab] = useState<string>(TABS[0].key);
	const [leadershipData, setLeadershipData] = useState<ISolLeadership[]>([]);
	const [userLotteryInfo, setUserLotteryInfo] = useState<any>(null);

	useEffect(() => {
		setLeadershipData([]);
		if (activeTab === TABS[0]?.key) {
			getRecentTx();
		} else if (activeTab === TABS[1]?.key) {
			getMyTx();
		}
	}, [activeTab, lotteryDetail]);

	const getRecentTx = () => {
		const currentTicket = get(lotteryDetail, 'spotNumbers', '').toString();
		if (currentTicket) {
			const ticketArr = currentTicket?.split(',');
			if (ticketArr && ticketArr?.length > 0) {
				const randomWallet = Keypair.generate();
				const res = ticketArr.map((item: any, index: number) => {
					return {
						key: item?.toString() + 'recent',
						username: minimizeAddress(randomWallet.publicKey?.toString()),
						avatar: '/images/logo.svg',
						game: get(GAME_TYPES, `[${lotteryDetail?.type}].name`, ''),
						number: item,
						price: get(lotteryDetail, 'priceTicket', 0) / 100,
						currencyName: get(lotteryDetail, 'currencySymbol', '')
					};
				});
				setLeadershipData(res);
			} else {
				setLeadershipData([]);
			}
		} else {
			setLeadershipData([]);
		}
	};

	const getMyTx = () => {
		if (userLotteryInfo) {
			const userTicket = get(userLotteryInfo, 'spotNumbers', '').toString();
			if (userTicket) {
				const ticketArr = userTicket?.split(',');
				if (ticketArr && ticketArr?.length > 0) {
					const res = ticketArr.map((item: any, index: number) => {
						return {
							key: item?.toString() + 'mine',
							username: minimizeAddress(
								anchorWallet?.publicKey.toString() || ''
							),
							avatar: '/images/logo.svg',
							game: get(GAME_TYPES, `[${lotteryDetail?.type}].name`, ''),
							number: item,
							price: get(lotteryDetail, 'priceTicket', 0) / 100,
							currencyName: get(lotteryDetail, 'currencySymbol', '')
						};
					});
					setLeadershipData(res);
				} else {
					setLeadershipData([]);
				}
			} else {
				setLeadershipData([]);
			}
		}
	};

	const handleTabChange = (tabKey: string) => {
		setActiveTab(tabKey);
	};

	const isLoading = useSelector(
		(state: AppState) => state.lotteryDetail.isLoadingTransaction
	);

	return (
		<>
			<OverlayLoading loading={isLoading} type="transaction" />
			<SolLotteryDetailTemplate
				summary={
					<SolLotteryInfo
						userLotteryInfo={userLotteryInfo}
						setUserLotteryInfo={setUserLotteryInfo}
					/>
				}
				tabs={
					<SolLotteryDetailTabs
						tabs={TABS}
						activeTab={activeTab}
						onTabChange={handleTabChange}
					/>
				}
				details={<SolLeadership data={leadershipData} activeTab={activeTab} />}
			/>
		</>
	);
};
export default SolLotteryDetailMainContainer;
