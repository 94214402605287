import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

interface ISolTextRunnintProps {
	text: string;
}

const SolTextRunnint: React.FC<ISolTextRunnintProps> = ({
	text
}: ISolTextRunnintProps) => {
	const [key, setKey] = useState(1);

	const scrolling = useSpring({
		from: { transform: 'translate(100%,0)' },
		to: { transform: 'translate(-100%,0)' },
		config: { duration: 6000 },
		reset: true,
		//reverse: key % 2 == 0,
		onRest: () => {
			setKey(key + 1);
		}
	});

	return (
		<div key={key} className='sol-text-running'>
			<animated.div style={scrolling}>{text}</animated.div>
		</div>
	);
};

export default SolTextRunnint;
