import { createReducer } from '@reduxjs/toolkit';
import { setSolBal, setUsdcBal } from './actions';
import { WalletState } from './types';

const initialState: WalletState = {
	solBal: 0,
	usdcBal: 0
};

const walletReducer = createReducer(initialState, builder => {
	builder
		.addCase(setSolBal, (state, action) => {
			state.solBal = action.payload;
		})
		.addCase(setUsdcBal, (state, action) => {
			state.usdcBal = action.payload;
		});
});

export default walletReducer;
