import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PublicKey } from '@solana/web3.js';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SITE_CONFIGS, SOLANA_EXPLORER_URL } from '../../../../../constants';
import { useSolBalance } from '../../../../../hooks/useState';
import { updateWalletInfo } from '../../../../../redux/application/actions';
import { solaUtils } from '../../../../../services/blockchain/ido.web3';
import {
	formatNumberDownRound,
	minimizeAddress
} from '../../../../../services/helpers/helpers';
import SolButton from '../../../../atoms/button';
import SolBreadcrumb from '../../../../molecules/breadcrumb';
import './index.scss';
import { Image } from 'react-bootstrap';

type SolHeaderProps = {
	breadcrumbs?: any;
	onClickWallet?: () => void;
	onToggleSidebar?: () => void;
};

const SolHeader = ({
	breadcrumbs,
	onClickWallet,
	onToggleSidebar,
}: SolHeaderProps) => {
	const dispatch = useDispatch();
	const { publicKey, connected, disconnect } = useWallet();
	const solBal = useSolBalance();

	useEffect(() => {
		if (publicKey && connected) {
			getWalletInfo(publicKey);
		} else {
			dispatch(
				updateWalletInfo({
					connected: false,
					address: '',
					walletUrl: '',
					solBalance: 0
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publicKey, connected]);

	const getWalletInfo = async (publicKey: PublicKey) => {
		try {
			const res = await solaUtils.getAddressInfo(String(publicKey));
			const walletAddress = publicKey.toBase58();
			dispatch(
				updateWalletInfo({
					connected: true,
					address: walletAddress,
					walletUrl: `${SOLANA_EXPLORER_URL}/address/${walletAddress}`,
					solBalance: res
				})
			);
		} catch (error) { }
	};

	return (
		<div className="sol-header">
			<Link to="/" className="sol-logo-sm">
				<Image src={SITE_CONFIGS.logoImage} alt="" />
			</Link>
			<SolBreadcrumb items={breadcrumbs} />
			<div className="sol-header-right">
				{!(publicKey && connected) ? (
					<WalletMultiButton className='sol-btn sol-btn-sm sol-btn-primary' />
				) : (
					<>
						<SolButton className="sol-btn-disconnect" size="sm" outline={false}>
							{`◎${formatNumberDownRound(solBal)}`}
						</SolButton>
						<SolButton className="sol-btn-your-wallet" size="sm" outline={false} onClick={onClickWallet}>
							<img src="/images/icons/wallet.svg" alt="" />
							{minimizeAddress(publicKey.toBase58())}
						</SolButton>
						<SolButton className="sol-btn-disconnect" size="sm" onClick={disconnect}>
							<span>Disconnect</span>
						</SolButton>
					</>
				)}
			</div>
			<button
				type="button"
				className="sol-btn-toggle"
				onClick={onToggleSidebar}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z"
						fill="white"
					/>
					<path
						d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z"
						fill="white"
					/>
					<path
						d="M21 17.75H3C2.59 17.75 2.25 17.41 2.25 17C2.25 16.59 2.59 16.25 3 16.25H21C21.41 16.25 21.75 16.59 21.75 17C21.75 17.41 21.41 17.75 21 17.75Z"
						fill="white"
					/>
				</svg>
			</button>
		</div>
	);
};
export default SolHeader;
