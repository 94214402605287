import { LotteryListItem } from "../../../types/lottery.type";


export interface LotteryState {
	lotteries: LotteryListItem[];
	isLoading: boolean;
}

export const GET_LOTTERIES_ACTION_REQUEST =
	'GET_LOTTERIES_ACTION_REQUEST';
export const GET_LOTTERIES_ACTION_SUCCESS =
	'GET_LOTTERIES_ACTION_SUCCESS';
export const GET_LOTTERIES_ACTION_FAIL = 'GET_LOTTERIES_ACTION_FAIL';

