import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlockLatest } from '../../hooks/useState';
import { AppState } from '../../redux/rootReducer';
import SolLotteryMainContainer from './components/main.container';
import { getLotteries } from './redux/actions';

const SolLotteryPage = () => {
	//get project from api and pass it to the main container component
	const blockNumber = useBlockLatest();
	const dispatch = useDispatch();

	const projects = useSelector((state: AppState) => state.lottery.lotteries);
	useEffect(() => {
		if (blockNumber || blockNumber === 0) dispatch(getLotteries());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blockNumber]);

	return <SolLotteryMainContainer projects={projects} />;
};
export default SolLotteryPage;
