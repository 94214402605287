import SolDashboardTemplate from '../../../components/templates/dashboard';
import SolDashboardBannerContainer from './banner.container';
import SolDashboardNewsContainer from './news.container';

const SolDashboardMainContainer = () => {
	return (
		<SolDashboardTemplate>
			<>
				<SolDashboardBannerContainer />
				<SolDashboardNewsContainer />
			</>
		</SolDashboardTemplate>
	);
};
export default SolDashboardMainContainer;
